import { Grid } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { mainBorder } from '../../constants/styles';
import { useRadarContext } from '../../hooks/contexts/useRadarContext';
import { EmptyObjectInfo } from './components/EmptyObjectInfo';
import { ObjectInfo } from './components/object-info/ObjectInfo';
import { SimilarObjectsInfo } from './components/similar-objects-info/SimilarObjectsInfo';

const sx = makeSxStyles({
    wrapper: {
        position: 'relative',
        height: '100%',
        marginLeft: 0,
        padding: 2,

        borderLeft: mainBorder,
    },
});

export const SidebarInfo = observer((): JSX.Element => {
    const { activeDataset, activeSimilarObjects } = useRadarContext();

    const sidebarContent = activeDataset ? (
        <ObjectInfo />
    ) : activeSimilarObjects ? (
        <SimilarObjectsInfo />
    ) : (
        <EmptyObjectInfo />
    );

    return (
        <Grid container direction="column" sx={sx.wrapper}>
            {sidebarContent}
        </Grid>
    );
});
