import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { IconButton, Tooltip } from '@mui/material';
import { SxStyle } from '@platform/front-types';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useRadarContext } from '../hooks/contexts/useRadarContext';
import { useStore } from '../hooks/contexts/useStore';

const sx: SxStyle = {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 10,
};

export const FullScreenButton = observer((): JSX.Element => {
    const { isFullscreen, toggleIsFullscreen } = useStore();
    const { isSidebarOpen, setIsSidebarOpen } = useRadarContext();

    const onClick = (): void => {
        if (!isFullscreen && isSidebarOpen) {
            setIsSidebarOpen(false);
        }
        toggleIsFullscreen();
        (document.activeElement as HTMLButtonElement)?.blur();
    };

    const tooltipTitle = isFullscreen ? 'Свернуть' : 'Развернуть';

    return (
        <Tooltip title={tooltipTitle}>
            <IconButton onClick={onClick} sx={sx}>
                {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
        </Tooltip>
    );
});
