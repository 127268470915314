import { grey, makeSxStyles } from '@platform/front-ui';
import { zIndexes } from '../../constants/zIndexes';

export const subsLegendSx = makeSxStyles({
    button: {
        position: 'absolute',
        bottom: 8,
        left: 8,
    },
    paper: {
        position: 'absolute',
        backgroundColor: grey[0],
        bottom: '8px',
        left: '8px',
        zIndex: zIndexes.subsLegend,
    },
    item: {
        paddingY: 1,
        paddingX: 2,
        borderRadius: '2px',
        backgroundColor: grey[100],
        marginBottom: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 'inherit',
    },
});
