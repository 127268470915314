import { Grid } from '@mui/material';
import { ConfirmationDialog } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { useRadarContext } from '../../hooks/contexts/useRadarContext';
import { useStore } from '../../hooks/contexts/useStore';
import { FileFormModel } from '../../models/FileFormModel';
import { RouteParams } from '../../types/routes';
import { FileUpload } from './components/file-upload/FileUpload';
import { FileSelect } from './components/FileSelect';

export const FileForm = observer((): JSX.Element => {
    const { handleManifestChange } = useRadarContext();
    const rootStore = useStore();
    const location = useLocation();
    const { id } = useParams<RouteParams>();

    const isRootPath = matchPath(location.pathname, clientRoute.root)?.isExact;

    const model = useMemo(() => new FileFormModel(rootStore), [rootStore]);
    const {
        isLoaded,
        active,
        toRemove,
        openDefaultFile,
        onDatasetChange,
        loadFiles,
        uploadFile,
        removeFile,
        dropToRemove,
    } = model;

    useEffect(() => {
        loadFiles(!id);
    }, []);

    useEffect(() => {
        isRootPath && openDefaultFile();
    }, [isRootPath]);

    useEffect(() => {
        handleManifestChange(active);
    }, [active]);

    useEffect(() => {
        isLoaded && onDatasetChange(id);
    }, [id, isLoaded]);

    return (
        <Grid container alignItems="center" wrap="nowrap" spacing={1}>
            <Grid item>
                <FileUpload onUpload={uploadFile} />
            </Grid>

            <Grid item>
                <FileSelect model={model} />
            </Grid>

            {toRemove && (
                <ConfirmationDialog
                    onCancel={dropToRemove}
                    id="remove-file"
                    onConfirm={removeFile}
                    title="Подтвердите удаление"
                    message={`Вы действительно хотите удалить датасет ${toRemove?.name}?`}
                    maxWidth="xs"
                    keepMounted={false}
                    open
                />
            )}
        </Grid>
    );
});
