import { DialogContent, Grid, Typography } from '@mui/material';
import { LinkButton } from '@platform/front-ui';
import { identity } from '@platform/front-utils';
import { Form, useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useDiagramContext } from '../../../../../hooks/contexts/useDiagramContext';
import { SimilarFilters, SimilarRelationType } from '../../../../../types/filters';
import { getDefaultSimilarFilters } from '../../../../../utils/filters';
import { SimilarFilterCheckbox } from './SimilarFilterCheckbox';

export const SimilarFiltersForm = observer((): JSX.Element => {
    const { setSimilarFilters } = useDiagramContext();
    const { values, setValues } = useFormikContext<SimilarFilters>();

    useEffect(() => {
        setSimilarFilters(values);
    }, [values]);

    const isAllSelected = Object.values(values).every(identity);

    const toggleAll = (): void => {
        const newValues = isAllSelected
            ? {
                  strong: false,
                  common: false,
                  weak: false,
              }
            : getDefaultSimilarFilters();
        setValues(newValues);
    };

    const toggleTitle = isAllSelected ? 'Снять все' : 'Выбрать все';

    return (
        <Form>
            <DialogContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography>Выберите связи для отображения</Typography>
                    </Grid>

                    <Grid item container direction="column">
                        <Grid item>
                            <LinkButton onClick={toggleAll}>{toggleTitle}</LinkButton>
                        </Grid>

                        <Grid item>
                            <SimilarFilterCheckbox
                                name={SimilarRelationType.strong}
                                label="Устойчивая связь"
                                tooltip="100% - 70%  совпадений"
                            />
                        </Grid>

                        <Grid item>
                            <SimilarFilterCheckbox
                                name={SimilarRelationType.common}
                                label="Связь"
                                tooltip="70% - 40% совпадений"
                            />
                        </Grid>

                        <Grid item>
                            <SimilarFilterCheckbox
                                name={SimilarRelationType.weak}
                                label="Косвенная связь"
                                tooltip="40% - 10% совпадений"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Form>
    );
});
