import { Button, DialogContent, Grid, Paper } from '@mui/material';
import { DialogTitle, EllipsisBox } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useFullscreenOnOpenAction } from '../../hooks/useFullscreenOnOpenAction';
import { subsLegendSx } from './subsLegendSx';

export type LegendProps = {
    items: string[];
};

export const SubsLegend = observer((props: LegendProps): JSX.Element => {
    const { items } = props;
    const [isLegendOpen, openLegend, closeLegend] = useFlag(true);
    useFullscreenOnOpenAction(closeLegend);

    const itemsElements = items.map((item): JSX.Element => {
        return (
            <Grid item key={item} sx={subsLegendSx.item}>
                <EllipsisBox text={item} correctLength={50} />
            </Grid>
        );
    });

    return (
        <React.Fragment>
            <Button variant="outlined" color="secondary" onClick={openLegend} sx={subsLegendSx.button}>
                Поднаправления
            </Button>

            {isLegendOpen && (
                <Paper elevation={1} sx={subsLegendSx.paper}>
                    <DialogTitle onCloseClick={closeLegend}>Поднаправления</DialogTitle>

                    <DialogContent>
                        <Grid container direction="column">
                            {itemsElements}
                        </Grid>
                    </DialogContent>
                </Paper>
            )}
        </React.Fragment>
    );
});
