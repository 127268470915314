import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Grid } from '@mui/material';
import { SxStyle } from '@platform/front-types';
import { mergeSxStyles } from '@platform/front-ui';
import { Form, useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useEffect } from 'react';
import { mainBorder } from '../../constants/styles';
import { useStore } from '../../hooks/contexts/useStore';
import { factorOptions, FiltersModel } from '../../models/FiltersModel';
import { DiagramFilters, SelectFilter } from '../../types/filters';
import { FilterAutocomplete } from './fields/FilterAutocomplete';
import { SearchField } from './fields/SearchField';
import { filtersSx } from './filtersSx';

export type FiltersFormProps = {
    model: FiltersModel;
};

const clearIcon = <ClearIcon />;

const formStyles: CSSProperties = {
    borderBottom: mainBorder,
};

export const FiltersForm = observer((props: FiltersFormProps): JSX.Element => {
    const { model } = props;

    const { colorsMap, searchOptions, initialValues, setFieldValues, handleSearch } = model;
    const { isDefaultView } = useStore();
    const { values, setValues } = useFormikContext<DiagramFilters>();

    useEffect(() => {
        setFieldValues(values);
    }, [values]);

    const onClearAll = (): void => {
        setValues(initialValues);
    };

    const renderTypesOptionLabel = (title: JSX.Element, value: string, isDropdown: boolean): JSX.Element => {
        const dotColorSx: SxStyle = {
            backgroundColor: colorsMap[value.toLowerCase()],
        };
        const dotSx = mergeSxStyles(filtersSx.dot, dotColorSx);
        return (
            <Box {...(isDropdown && { sx: filtersSx.dropdownTitleWrapper })}>
                <Box sx={dotSx} />
                {title}
            </Box>
        );
    };

    return (
        <Form style={formStyles}>
            <Grid
                container
                justifyContent="space-between"
                wrap="nowrap"
                alignItems="center"
                sx={filtersSx.container}
                spacing={2}
            >
                <Grid item container wrap="nowrap" spacing={2} alignItems="center">
                    <Grid item>
                        <SearchField options={searchOptions} handleSearch={handleSearch} />
                    </Grid>

                    <Grid item>
                        <FilterAutocomplete
                            fieldName={SelectFilter.types}
                            label="Тип объекта"
                            renderOptionLabel={renderTypesOptionLabel}
                            options={initialValues.types}
                        />
                    </Grid>

                    {isDefaultView && (
                        <Grid item>
                            <FilterAutocomplete
                                fieldName={SelectFilter.factors}
                                label="Масштабность"
                                options={factorOptions}
                            />
                        </Grid>
                    )}
                </Grid>

                <Grid item mt={0.75}>
                    <Button startIcon={clearIcon} variant="outlined" color="secondary" onClick={onClearAll}>
                        Сбросить
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
});
