import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, Tooltip } from '@mui/material';
import { FieldWithServerError, makeSxStyles } from '@platform/front-ui';
import { CheckboxWithLabel } from 'formik-mui';
import { CheckboxWithLabelProps } from 'formik-mui/dist/CheckboxWithLabel';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SimilarRelationType } from '../../../../../types/filters';

export type SimilarFilterCheckboxProps = {
    name: SimilarRelationType;
    label: string;
    tooltip: string;
};

const sx = makeSxStyles({
    label: {
        minWidth: '165px',
    },
});

export const SimilarFilterCheckbox = observer((props: SimilarFilterCheckboxProps): JSX.Element => {
    const { name, label, tooltip } = props;

    const fieldLabel: CheckboxWithLabelProps['Label'] = {
        label: label,
        sx: sx.label,
    };

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item>
                <FieldWithServerError
                    name={name}
                    component={CheckboxWithLabel}
                    type="checkbox"
                    Label={fieldLabel}
                    fullWidth
                />
            </Grid>
            <Grid item>
                <Tooltip title={tooltip}>
                    <InfoOutlinedIcon cursor="pointer" />
                </Tooltip>
            </Grid>
        </Grid>
    );
});
