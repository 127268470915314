import RightArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton } from '@mui/material';
import { grey, makeSxStyles, mergeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';

export type SidebarInfoToggleButtonProps = {
    onToggle: VoidFunction;
    open: boolean;
};

const sx = makeSxStyles({
    button: {
        position: 'absolute',
        left: '-10px',
        top: '40px',
        height: 32,
        width: 16,
        borderRadius: 0,
        borderBottomLeftRadius: 4,
        borderTopLeftRadius: 4,
        backgroundColor: grey[100],

        '& svg': {
            transition: 'transform 0.3s ease-in-out',
            transform: 'rotate(180deg)',
        },
    },
    open: {
        svg: {
            transition: 'transform 0.3s ease-in-out',
            transform: 'rotate(0deg)',
        },
    },
});

export const SidebarInfoToggleButton = observer((props: SidebarInfoToggleButtonProps): JSX.Element => {
    const { open, onToggle } = props;

    const sxStyles = mergeSxStyles(sx.button, open ? sx.open : {});

    return (
        <IconButton onClick={onToggle} sx={sxStyles}>
            <RightArrowIcon />
        </IconButton>
    );
});
