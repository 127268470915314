import { LoadingModel, voidFunction } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { RefObject } from 'react';
import { DiagramStore } from '../../stores/DiagramStore';
import { RootStore } from '../../stores/RootStore';
import { MouseHandle, SvgRefs, ViewBox } from '../../types/diagram';
import { DiagramModel } from '../DiagramModel';
import { RadarModel } from '../RadarModel';

export const svgFieldsModelObservables = {
    rootStore: observable,
    diagramStore: observable,

    id: observable,
    fileName: observable,
    diameter: observable,
    padding: observable,
    svg: observable,
    viewBox: observable,

    diagramModel: observable,
    onPointClick: observable,
    onSimilarLineClick: observable,

    holderElement: observable,
    panHandler: observable,
    mouseHandler: observable,

    setSvg: action.bound,
    setViewBox: action.bound,
    setId: action.bound,
    setFileName: action.bound,
    setDiameter: action.bound,
    setPadding: action.bound,
    setOnPointClick: action.bound,
    setOnSimilarLineClick: action.bound,
    setHolderElement: action.bound,
};

export class SvgFieldsModel extends LoadingModel {
    protected rootStore: RootStore;
    protected diagramStore: DiagramStore;

    id = '';
    fileName = '';
    diameter = 0;
    padding = 0;
    svg = '';
    viewBox: ViewBox = {
        x: 0,
        y: 0,
        scale: 0,
    };

    diagramModel: DiagramModel;
    onPointClick: RadarModel['handlePointClick'] = voidFunction;
    onSimilarLineClick: RadarModel['handleSimilarLineClick'] = voidFunction;

    holderElement: HTMLDivElement | null = null;
    panHandler: RefObject<MouseHandle>;
    mouseHandler: RefObject<MouseHandle>;

    constructor(rootStore: RootStore, diagramModel: DiagramModel, refs: SvgRefs) {
        super();
        const { panHandler, mouseHandler } = refs;
        this.rootStore = rootStore;
        this.diagramStore = rootStore.diagramStore;
        this.diagramModel = diagramModel;

        this.panHandler = panHandler;
        this.mouseHandler = mouseHandler;

        this.isLoading = false;
        makeObservable(this, svgFieldsModelObservables);
    }

    setSvg(svg: string): void {
        this.svg = svg;
    }

    setViewBox(viewBox: ViewBox): void {
        this.viewBox = viewBox;
    }

    setId(id: string): void {
        this.id = id;
    }

    setFileName(fileName: string): void {
        this.fileName = fileName;
    }

    setDiameter(diameter: number): void {
        this.diameter = diameter;
    }

    setPadding(padding: number): void {
        this.padding = padding;
    }

    setOnPointClick(onPointClick: RadarModel['handlePointClick']): void {
        this.onPointClick = onPointClick;
    }

    setOnSimilarLineClick(onSimilarLineClick: RadarModel['handleSimilarLineClick']): void {
        this.onSimilarLineClick = onSimilarLineClick;
    }

    setHolderElement(element: HTMLDivElement | null): void {
        this.holderElement = element;
    }
}
