import { action, makeObservable, observable } from 'mobx';
import { Message } from '../types/message';

export const notificationStoreObservables = {
    messages: observable,

    add: action.bound,
    shift: action.bound,
    clear: action.bound,
    setMessages: action.bound,
};

export class NotificationStore {
    messages: Message[] = [];

    constructor() {
        makeObservable(this, notificationStoreObservables);
    }

    add(message: Message): void {
        this.setMessages([...this.messages, message]);
    }

    shift(): void {
        this.setMessages(this.messages.slice(1));
    }

    clear(): void {
        this.setMessages([]);
    }

    setMessages(messages: Message[]): void {
        this.messages = messages;
    }
}
