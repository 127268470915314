import { History } from 'history';
import { action, computed, makeObservable, observable } from 'mobx';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../clientRoute';
import { SubsTooltip } from '../components/subs-tooltip/SubsTooltip';
import { RootStore } from '../stores/RootStore';
import { ExtendedDataset } from '../types/dataset';
import { SubLevel, TooltipEvent, TooltipParams, TooltipType } from '../types/diagram';
import { SimilarFilters } from '../types/filters';
import { getDefaultSimilarFilters } from '../utils/filters';
import { getSearchParams } from '../utils/searchParams';

export const diagramModelObservables = {
    rootStore: observable,
    history: observable,

    tooltip: observable,
    scaleLevel: observable,
    activeSlice: observable,
    subLevel: observable,
    dataset: observable,

    similarFilters: observable,

    scaleCount: computed,
    scaleLevelMax: computed,
    sliceCount: computed,

    handleScale: action.bound,
    handleTooltip: action.bound,
    handleSliceClick: action.bound,
    handleSubSliceClick: action.bound,
    onSubLevelChange: action.bound,

    dropStates: action.bound,
    dropScaleLevel: action.bound,
    dropTooltip: action.bound,
    dropSimilarFilters: action.bound,
    setTooltip: action.bound,
    setScaleLevel: action.bound,
    setActiveSlice: action.bound,
    setSubLevel: action.bound,
    setDataset: action.bound,
    setSimilarFilters: action.bound,
};

export class DiagramModel {
    protected rootStore: RootStore;
    protected history: History;

    tooltip: TooltipParams | null = null;
    scaleLevel = 0;
    activeSlice = -1;
    subLevel: SubLevel | null = null;
    dataset: ExtendedDataset | null = null;

    similarFilters: SimilarFilters = getDefaultSimilarFilters();

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.history = rootStore.coreRootStore.history;

        makeObservable(this, diagramModelObservables);
    }

    get scaleCount(): number {
        return this.dataset?.scalesCount || 0;
    }

    get scaleLevelMax(): number {
        return this.dataset?.scalesCount || 0;
    }

    get sliceCount(): number {
        return (this.subLevel ? this.dataset?.subSlices[this.subLevel.id].length : this.dataset?.slices.length) || 0;
    }

    handleScale(value: number): void {
        let newScale = this.scaleLevel - value;

        if (newScale < 0) {
            newScale = 0;
        }

        if (newScale > this.scaleLevelMax) {
            newScale = this.scaleLevelMax;
        }

        this.setScaleLevel(newScale);
    }

    handleTooltip(tooltip: TooltipEvent | null): void {
        if (!tooltip) {
            this.dropTooltip();
            return;
        }

        const text = tooltip.isSub ? (
            <SubsTooltip title={this.dataset?.slices[+tooltip.id]} items={this.dataset?.subSlices[tooltip.id]} />
        ) : (
            tooltip.labelText || this.dataset?.items[tooltip.id]?.name || ''
        );

        this.setTooltip({
            left: tooltip.left,
            top: tooltip.top,
            text,
            type: tooltip.isSub ? TooltipType.bottom : TooltipType.top,
        });
    }

    handleSliceClick(id: string, sliceId: number): void {
        const subLevelId = this.subLevel?.id;
        const searchParams = getSearchParams();
        if (subLevelId !== undefined) {
            this.history.push(generatePath(clientRoute.subLevelSlice, { id, subLevelId, sliceId }) + searchParams);
        } else {
            this.history.push(generatePath(clientRoute.slice, { id, sliceId }) + searchParams);
        }
        this.dropScaleLevel();
    }

    handleSubSliceClick(id: string, subLevelId: number): void {
        this.setActiveSlice(-1);
        this.dropScaleLevel();
        this.history.push(generatePath(clientRoute.subLevel, { id, subLevelId }) + getSearchParams());
        this.dropTooltip();
    }

    onSubLevelChange(subLevelId: number | null): void {
        const isSubLevelNull = subLevelId === null;
        this.setSubLevel(
            isSubLevelNull
                ? null
                : {
                      id: subLevelId,
                      title: this.dataset?.slices[subLevelId] || '',
                  },
        );
    }

    dropStates(): void {
        this.dropScaleLevel();
        this.setActiveSlice(-1);
        this.setSubLevel(null);
        this.dropTooltip();
    }

    dropScaleLevel(): void {
        this.scaleLevel = 0;
    }

    dropTooltip(): void {
        this.tooltip = null;
    }

    dropSimilarFilters(): void {
        this.similarFilters = getDefaultSimilarFilters();
    }

    setTooltip(tooltip: TooltipParams): void {
        this.tooltip = tooltip;
    }

    setScaleLevel(scaleLevel: number): void {
        this.scaleLevel = scaleLevel;
    }

    setActiveSlice(activeSlice: number): void {
        this.activeSlice = activeSlice;
    }

    setSubLevel(subLevel: SubLevel | null): void {
        this.subLevel = subLevel;
    }

    setDataset(dataset: ExtendedDataset | null): void {
        this.dataset = dataset;
    }

    setSimilarFilters(similarFilters: SimilarFilters): void {
        this.similarFilters = similarFilters;
    }
}
