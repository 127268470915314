import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import { ToggleButton } from '@mui/lab';
import { Box, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useRadarContext } from '../../../hooks/contexts/useRadarContext';
import { useStore } from '../../../hooks/contexts/useStore';
import { RouteParams } from '../../../types/routes';
import { RadarView, SettingsGroup } from '../../../types/settings';

export const RadarViewButton = observer((): JSX.Element => {
    const { isDefaultView, setViewSearchParam } = useStore();
    const { dataset, dropActiveSimilarLineSearchParams } = useRadarContext();
    const { id = '' } = useParams<RouteParams>();

    const onViewClick = (): void => {
        if (!isDefaultView) {
            dropActiveSimilarLineSearchParams();
        }
        setViewSearchParam(id, isDefaultView ? RadarView.similar : RadarView.default);
    };
    const isDisabled = !dataset?.isWithSimilarDiagram;

    const tooltip = isDisabled
        ? 'Диаграмма связей отсутствует'
        : isDefaultView
        ? 'Включить отображение связей'
        : 'Отключить отображение связей';

    let toggleButton = (
        <ToggleButton
            className="MuiToggleButtonGroup-grouped"
            value={SettingsGroup.view}
            selected={!isDefaultView}
            onClick={onViewClick}
            disabled={isDisabled}
        >
            <HubOutlinedIcon />
        </ToggleButton>
    );

    if (isDisabled) {
        // Обертка нужна для работы тултипа
        toggleButton = <Box>{toggleButton}</Box>;
    }

    return <Tooltip title={tooltip}>{toggleButton}</Tooltip>;
});
