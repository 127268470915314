import axios, { AxiosError, AxiosInstance } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { MessageType } from '../types/message';
import { Env } from '../types/rootStore';
import { NotificationStore } from './NotificationStore';
import { RootStore } from './RootStore';

export const apiStoreObservables = {
    notificationStore: observable,
    client: observable,

    rejectInterceptor: action.bound,
};

export class ApiStore {
    protected notificationStore: NotificationStore;
    client: AxiosInstance;

    constructor(rootStore: RootStore) {
        this.notificationStore = rootStore.notificationStore;
        const { apiRadarUrl } = rootStore.coreRootStore.env as Env;
        this.client = axios.create({
            baseURL: apiRadarUrl,
            timeout: 30000,
        });
        this.client.interceptors.response.use((_) => _, this.rejectInterceptor.bind(this));

        makeObservable(this, apiStoreObservables);
    }

    rejectInterceptor(error: AxiosError): Promise<void> {
        const text = error.response?.data?.message || `Произошла ошибка (${error.response?.status || 0})`;

        this.notificationStore.add({
            text: text.replace(/^Error: /, ''),
            type: MessageType.error,
        });

        return Promise.reject(error);
    }
}
