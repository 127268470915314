const fullScreenZIndex = 999;
const aboveFullScreenZIndex = fullScreenZIndex + 1;
const subsLegendZIndex = aboveFullScreenZIndex;
const legendZIndex = subsLegendZIndex + 1;

export const zIndexes = {
    fullScreen: fullScreenZIndex,
    resizePanel: aboveFullScreenZIndex,
    similarFilters: aboveFullScreenZIndex,
    subsLegend: subsLegendZIndex,
    legend: legendZIndex,
    tooltip: legendZIndex + 1,
    alert: 9999,
};
