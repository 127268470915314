import { LocalesTree } from '@platform/front-utils';
import instructionSrc from '../../instructions/ru_instr.docx';

export const commonLocalesRu: LocalesTree = {
    instructionSrc: instructionSrc,
    instructionFileName: 'Инструкция.docx',
    edit: 'Редактировать',
    save: 'Сохранить',
    saveChanges: 'Сохранить изменения',
    delete: 'Удалить',
    cancel: 'Отменить',
    discardChanges: 'Отменить изменения',
    changesSaved: 'Изменения сохранены',
    selectValue: 'Выберите значение',
    close: 'Закрыть',
    error: 'Ошибка',
    errorText: 'Что-то пошло не так',
    defaultErrorMessage: 'Что-то пошло не так',
    confirm: 'Подтвердить',
    create: 'Создать',
    noData: 'Нет данных',
    from: 'c',
    to: 'до',
    until: 'по',
    state: 'Состояние',
    rowsPerPage: 'Отображать по:',
    pagingInfo: 'Отображено c {from, number} по {to, number}. Всего элементов {count, number}',
    resetFilters: 'Сбросить',
    filters: 'Фильтры',
    all: 'Все',
    actions: 'Действия',
    export: 'Выгрузить',
    confirmDeletion: 'Подтвердите удаление',
    dropzoneText: 'Перетащите или выберите файл',
    pageNotFound: 'Страница не найдена',
    actionNotAllowed: 'К сожалению, у вас недостаточно прав для совершения данного действия',
    downloadFile: 'Скачать {fileName}',
    yes: 'Да',
    no: 'Нет',
    lifeCycleConfirmTitle: 'Изменить статус',
    lifeCycleConfirmText: 'Статус будет изменен на «{toState}»',
    megabytes: 'Мб',
    add: 'Добавить',
    setUp: 'Настроить',
    startDate: 'Дата начала',
    endDate: 'Дата окончания',
    form: 'Форма',
    lifecycle: 'ЖЦ',
    lifecycleStates: 'Состояния ЖЦ',
    allStates: 'Все состояния',
    rename: 'Переименовать',
    access: 'Доступ',
    setUpAccess: 'Настроить доступ',
    code: 'Код',
    name: 'Наименование',
    description: 'Описание',
    lastModified: 'Последнее изменение',
    settings: 'Настройки',
    result: 'Результат',
    clear: 'Очистить',
    copy: 'Копировать',
    goBack: 'Вернуться назад',
    noOptions: 'Нет доступных вариантов',
};
