import { grey, makeSxStyles } from '@platform/front-ui';

export const subsTooltipSx = makeSxStyles({
    container: {
        alignItems: 'flex-start',
        color: grey[900],
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '240px',
    },
    info: {
        color: grey[500],
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '16px',
        margin: 0,
        marginBottom: 1,
    },
    title: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '16px',
        margin: 0,
        marginBottom: 1,
        maxWidth: '240px',
    },
    item: {
        backgroundColor: grey[100],
        borderRadius: '2px',
        cursor: 'pointer',
        marginBottom: 1,
        overflow: 'hidden',
        paddingY: 0.5,
        paddingX: 1,
    },
});
