import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { diagramFileNames } from '../../constants/diagram';
import { DiagramContext } from '../../contexts/DiagramContext';
import { useRadarContext } from '../../hooks/contexts/useRadarContext';
import { useStore } from '../../hooks/contexts/useStore';
import { useDiagramRouteStates } from '../../hooks/useDiagramRouteStates';
import { DiagramModel } from '../../models/DiagramModel';
import { RouteParams } from '../../types/routes';
import { FullScreenButton } from '../FullScreenButton';
import { Legend } from '../legend/Legend';
import { ScaleSlider } from '../scale-slider/ScaleSlider';
import { SettingsGroupButton } from '../settings/SettingsGroupButton';
import { SubsLegend } from '../subs-legend/SubsLegend';
import { Tooltip } from '../Tooltip';
import { DiagramBreadcrumbs } from './components/DiagramBreadcrumbs';
import { Svg } from './components/svg/Svg';
import { diagramSx } from './diagramSx';

export const Diagram = observer((): JSX.Element => {
    const radarModel = useRadarContext();
    const { constants, dataset } = radarModel;
    const rootStore = useStore();
    const { id = '', subLevelId = '' } = useParams<RouteParams>();
    const { isDefaultView } = rootStore;

    const model = useMemo(() => new DiagramModel(rootStore), [rootStore]);
    const { scaleLevel, subLevel, activeSlice, tooltip, scaleLevelMax, dropStates, setScaleLevel, setDataset } = model;
    useDiagramRouteStates(model, radarModel);

    useEffect(() => {
        setDataset(dataset);
    }, [dataset]);

    useEffect(() => {
        dropStates();
    }, [id]);

    const isActiveSlice = activeSlice !== -1;
    const isWithSubsLegend = isActiveSlice && !!dataset?.subSlices?.[activeSlice]?.length && !subLevel;

    const fileName = isDefaultView
        ? subLevelId
            ? `sub${subLevelId}`
            : diagramFileNames.default
        : diagramFileNames.similar;
    const diameter = ((constants?.radius || 0) + (constants?.padding || 0)) * 2;

    return (
        <DiagramContext.Provider value={model}>
            <Grid container direction="column" wrap="nowrap" flex={1} sx={diagramSx.wrapper}>
                {id ? (
                    <React.Fragment>
                        <DiagramBreadcrumbs />
                        <ScaleSlider
                            key={`${id}-${activeSlice}`}
                            onChange={setScaleLevel}
                            max={scaleLevelMax}
                            step={1}
                            value={scaleLevel}
                        />
                        <SettingsGroupButton />
                    </React.Fragment>
                ) : (
                    <Grid container item flex={1} justifyContent="center" alignItems="center">
                        Выберите датасет
                    </Grid>
                )}

                {id && <Svg id={id} fileName={fileName} diameter={diameter} padding={constants?.padding || 0} />}

                {isWithSubsLegend && <SubsLegend items={dataset.subSlices[activeSlice]} />}

                <FullScreenButton />
                <Legend />
            </Grid>

            <Tooltip params={tooltip} />
        </DiagramContext.Provider>
    );
});
