import { grey, makeSxStyles } from '@platform/front-ui';
import { zIndexes } from '../../constants/zIndexes';

export const legendSx = makeSxStyles({
    button: {
        position: 'absolute',
        bottom: '8px',
        right: '8px',
    },
    paper: {
        position: 'absolute',
        backgroundColor: grey[0],
        maxWidth: '338px',
        bottom: '8px',
        right: '8px',
        zIndex: zIndexes.legend,
    },
});
