import { action, makeObservable } from 'mobx';
import React from 'react';
import { diagramClasses } from '../../constants/diagram';
import { RootStore } from '../../stores/RootStore';
import { SvgRefs } from '../../types/diagram';
import { semiArcPath, setOffsetWithLimit } from '../../utils/svgUtils';
import { DiagramModel } from '../DiagramModel';
import { SvgFieldsModel } from './SvgFieldsModel';

export const svgModelObservables = {
    load: action.bound,
    getSubsetElement: action.bound,
    updateViewBox: action.bound,
    onViewBoxChange: action.bound,

    panStart: action.bound,
    panMove: action.bound,
    panStop: action.bound,

    highlightLabel: action.bound,
    mouseOver: action.bound,
    mouseOut: action.bound,
    sectorScale: action.bound,

    setCursor: action.bound,
    setWidth: action.bound,
};

export class SvgModel extends SvgFieldsModel {
    constructor(rootStore: RootStore, diagramModel: DiagramModel, refs: SvgRefs) {
        super(rootStore, diagramModel, refs);
        makeObservable(this, svgModelObservables);
    }

    load(): void {
        this.setSvg('');

        this.startLoading();
        this.diagramStore.getDiagram(this.id, this.fileName).then(this.setSvg).finally(this.stopLoading);
    }

    getSubsetElement(id: string | number): Element | null {
        return document.querySelector(`.${diagramClasses.svg} svg path[data-id="${id}"][data-type="subs"]`);
    }

    updateViewBox(): void {
        const { activeSlice, scaleLevel, scaleLevelMax } = this.diagramModel;
        const start = activeSlice === -1 ? 0 : this.padding;
        const max = activeSlice === -1 ? this.diameter : this.diameter / 2;
        const viewStep = (max - this.padding) / (activeSlice === -1 ? 1 : 1.75) / scaleLevelMax;
        const viewBoxScale = max - start - scaleLevel * viewStep;

        this.setViewBox({
            x: setOffsetWithLimit(this.viewBox.x + (this.viewBox.scale - viewBoxScale) / 2, viewBoxScale, max, start),
            y: setOffsetWithLimit(this.viewBox.y + (this.viewBox.scale - viewBoxScale) / 2, viewBoxScale, max, start),
            scale: viewBoxScale,
        });
    }

    onViewBoxChange(): void {
        if (!this.panHandler.current?.isDragging) {
            this.setCursor('grab');
        }
        document
            .querySelector(`.${diagramClasses.svg} svg`)
            ?.setAttribute(
                'viewBox',
                `${this.viewBox.x} ${this.viewBox.y} ${this.viewBox.scale} ${this.viewBox.scale}`,
            );
    }

    panStart(event: React.MouseEvent): void {
        if (!this.panHandler.current || !this.mouseHandler.current) {
            return;
        }

        this.panHandler.current.isDragging = true;
        this.panHandler.current.x = event.pageX;
        this.panHandler.current.y = event.pageY;

        this.mouseHandler.current.x = event.pageX;
        this.mouseHandler.current.y = event.pageY;

        document.addEventListener('mouseup', this.panStop);

        this.setCursor('grabbing');
    }

    panMove(event: React.MouseEvent): void {
        if (!this.panHandler.current || !this.panHandler.current.isDragging) {
            return;
        }
        const { activeSlice } = this.diagramModel;

        const offsetX = this.panHandler.current.x - event.pageX;
        const offsetY = this.panHandler.current.y - event.pageY;
        const start = activeSlice === -1 ? 0 : this.padding;
        const max = activeSlice === -1 ? this.diameter : this.diameter / 2;

        const { scale } = this.viewBox;
        const ratio = max / this.viewBox.scale;
        this.setViewBox({
            x: setOffsetWithLimit(this.viewBox.x + offsetX / ratio, scale, max, start),
            y: setOffsetWithLimit(this.viewBox.y + offsetY / ratio, scale, max, start),
            scale,
        });

        this.panHandler.current.x = event.pageX;
        this.panHandler.current.y = event.pageY;
    }

    panStop(event: Event): void {
        const { isDefaultView } = this.rootStore;

        if (!this.panHandler.current || !this.mouseHandler.current) {
            return;
        }

        this.panHandler.current.isDragging = false;
        document.removeEventListener('mouseup', this.panStop);
        this.setCursor('grab');

        // click
        if (
            (event as MouseEvent).pageX === this.mouseHandler.current.x &&
            (event as MouseEvent).pageY === this.mouseHandler.current.y
        ) {
            const { dataset } = event.target as SVGElement;
            const { activeSlice, handleSubSliceClick, handleSliceClick } = this.diagramModel;

            if (dataset.type === 'point') {
                this.onPointClick(dataset.id || '');
            }

            if (isDefaultView) {
                if (dataset.type === 'slice-label' && this.getSubsetElement(dataset.id || '')) {
                    handleSubSliceClick(this.id, Number(dataset.id === undefined ? -1 : dataset.id));
                    return;
                }

                if (dataset.type === 'subs') {
                    handleSubSliceClick(this.id, Number(dataset.id === undefined ? -1 : dataset.id));
                    return;
                }

                if (dataset.type === 'path' && activeSlice === -1) {
                    handleSliceClick(this.id, Number(dataset.id === undefined ? -1 : dataset.id));
                    return;
                }
            } else if (dataset.type === 'similar-line') {
                this.onSimilarLineClick(event.target as SVGElement);
                return;
            }

            return;
        }
    }

    highlightLabel(id: string | number): void {
        const { isDefaultView } = this.rootStore;

        if (isDefaultView && this.getSubsetElement(id)) {
            // подсветка заголовка сектора только если есть поднаправления
            const label = document.querySelector(`.${diagramClasses.svg} g.slice-label[data-id="${id}"]`);
            if (label) {
                (label as SVGElement).classList.remove(diagramClasses.inactive);
                (label as SVGElement).classList.add(diagramClasses.active);
            }
        }
    }

    mouseOver(event: React.MouseEvent): void {
        const { isDefaultView } = this.rootStore;

        const target = event.target as SVGElement;
        const { dataset, parentNode } = target;
        const { handleTooltip } = this.diagramModel;

        if (isDefaultView && (dataset.type === 'path' || dataset.type === 'point' || dataset.type === 'subs')) {
            document
                .querySelectorAll(
                    `.${diagramClasses.svg} svg g, .${diagramClasses.svg} svg text[data-type="slice-label"]`,
                )
                ?.forEach((element) => {
                    element.classList.remove(diagramClasses.active, diagramClasses.inactive);
                    element.classList.add(diagramClasses.inactive);
                });
            (parentNode as SVGElement).classList.remove(diagramClasses.inactive);
            (parentNode as SVGElement).classList.add(diagramClasses.active);

            if (dataset.type === 'subs') {
                (event.target as SVGElement).classList.add(diagramClasses.active);
                this.highlightLabel(dataset.id || '');

                handleTooltip({
                    left: event.pageX,
                    top: event.pageY,
                    id: dataset.id || '',
                    isSub: true,
                });
            }
        }

        if (dataset.type === 'point') {
            const rect = (event.target as SVGElement).getBoundingClientRect();

            handleTooltip({
                left: rect.left + rect.width / 2,
                top: rect.top,
                id: dataset.id || '',
            });
        }

        if (dataset.type === 'scale-label') {
            const rect = (event.target as SVGElement).getBoundingClientRect();

            handleTooltip({
                left: rect.left + rect.width / 2,
                top: rect.top,
                id: '',
                labelText: dataset.title,
            });
        }

        if (dataset.type === 'slice-label') {
            const subsElement = this.getSubsetElement(dataset.id || '');
            if (subsElement) {
                this.highlightLabel(dataset.id || '');

                // подсветка элемента поднаправлений и попап
                (subsElement as SVGElement).classList.remove(diagramClasses.inactive);
                (subsElement as SVGElement).classList.add(diagramClasses.active);

                handleTooltip({
                    left: event.pageX,
                    top: event.pageY,
                    id: dataset.id || '',
                    isSub: true,
                });
            } else if (dataset.title) {
                // заголовок, если длинный
                const rect = (event.target as SVGElement).getBoundingClientRect();

                handleTooltip({
                    left: rect.left + rect.width / 2,
                    top: rect.top,
                    id: '',
                    labelText: dataset.title,
                });
            }
        }
    }

    mouseOut(): void {
        const { handleTooltip } = this.diagramModel;

        document
            .querySelectorAll(
                `.${diagramClasses.svg} svg g, .${diagramClasses.svg} svg g[data-type="slice-label"], .${diagramClasses.svg} svg g path[data-type="subs"]`,
            )
            ?.forEach((element) => element.classList.remove(diagramClasses.active, diagramClasses.inactive));
        handleTooltip(null);
    }

    sectorScale(): void {
        const { activeSlice, sliceCount } = this.diagramModel;

        if (activeSlice > -1) {
            this.setViewBox({ x: this.padding, y: this.padding, scale: this.diameter / 2 - this.padding });
            if (this.holderElement) {
                this.holderElement.style.cursor = 'default';
            }

            // отношение развернутого сектора (90 градусов) к начальному (360 / количество секторов)
            const angleRatio = 90 / (360 / sliceCount);
            // точка, относительно которой вращаем (так как диаграмма круговая, то x и y совпадают и равны радиусу круга)
            const center = this.diameter / 2;

            document.querySelectorAll(`.${diagramClasses.svg} path[data-id="${activeSlice}"]`)?.forEach((element) => {
                element.setAttribute('d', semiArcPath(center, this.padding));
            });

            // скрываем другие группы (секторы, в том числе фоновые), но оставим группу с кольцами
            document.querySelectorAll(`.${diagramClasses.svg} g`)?.forEach((element) => {
                if (element.classList.contains('scales')) {
                    // у группы с кольцами включаем обрезку, чтоб не было видно то, что вне сегмента
                    element.setAttribute('clip-path', 'url(#cut)');
                    return;
                }

                if (element.classList.contains('labels')) {
                    // у группы с лейблами транслируем вверх на высоту группы
                    const height =
                        (document.querySelector(`.${diagramClasses.svg} g.labels rect`) as SVGElement).getAttribute(
                            'height',
                        ) || 0;
                    (element as SVGElement).style.transform = `translateY(-${height}px)`;
                    return;
                }

                if ((element as SVGElement).dataset.id !== activeSlice.toString()) {
                    (element as SVGElement).style.display = 'none';
                }
            });

            document
                .querySelectorAll(`.${diagramClasses.svg} g[data-id="${activeSlice}"] circle`)
                ?.forEach((element) => {
                    const { dataset } = element as SVGElement;

                    // начальный угол сектора
                    const sliceAngle = Number(dataset.sectorangle);
                    // угол точки
                    const angle = Number(dataset.angle);

                    // начальный угол развернутого сектора (180) + угол, увеличенный на соотношение
                    const newAngle = 180 + (angle - sliceAngle) * angleRatio;

                    const cx = center + Number(dataset.radius) * Math.cos((newAngle * Math.PI) / 180);
                    const cy = center + Number(dataset.radius) * Math.sin((newAngle * Math.PI) / 180);

                    element.setAttribute('cx', cx.toString());
                    element.setAttribute('cy', cy.toString());
                });

            document
                .querySelectorAll(`.${diagramClasses.svg} g[data-id="${activeSlice}"] circle`)
                ?.forEach((element) => {
                    const { dataset } = element as SVGElement;

                    // начальный угол сектора
                    const sliceAngle = Number(dataset.sectorangle);
                    // угол точки
                    const angle = Number(dataset.angle);

                    // начальный угол развернутого сектора (180) + угол, увеличенный на соотношение
                    const newAngle = 180 + (angle - sliceAngle) * angleRatio;

                    const cx = center + Number(dataset.radius) * Math.cos((newAngle * Math.PI) / 180);
                    const cy = center + Number(dataset.radius) * Math.sin((newAngle * Math.PI) / 180);

                    element.setAttribute('cx', cx.toString());
                    element.setAttribute('cy', cy.toString());
                });
        } else {
            // сброс

            document.querySelectorAll(`.${diagramClasses.svg} path:not(.similar-line)`)?.forEach((element) => {
                const { dataset } = element as SVGElement;
                element.setAttribute('d', dataset.d || '');
            });

            document.querySelectorAll(`.${diagramClasses.svg} g`)?.forEach((element) => {
                element.setAttribute('clip-path', 'none');
                (element as SVGElement).style.transform = 'none';
                (element as SVGElement).style.display = 'unset';
            });

            document.querySelectorAll(`.${diagramClasses.svg} circle.point`)?.forEach((element) => {
                const { dataset } = element as SVGElement;
                element.setAttribute('cx', dataset.cx || '');
                element.setAttribute('cy', dataset.cy || '');
            });
        }
    }

    setCursor(cursor: string): void {
        if (this.holderElement) {
            this.holderElement.style.cursor = this.viewBox.scale !== this.diameter ? cursor : 'default';
        }
    }

    setWidth(): void {
        const svg = document.querySelector<SVGElement>(`.${diagramClasses.svg} svg`);
        if (svg && this.holderElement) {
            svg.style.display = 'none';
            const { height, width } = (this.holderElement.parentNode as HTMLDivElement).getBoundingClientRect();
            svg.style.height = `${height - 16}px`;
            svg.style.width = `${width}px`;
            svg.style.display = 'block';
        }
    }
}
