import { Breadcrumbs } from '@mui/material';
import { BreadcrumbsLink, makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { mainBorder } from '../../../constants/styles';
import { useDiagramContext } from '../../../hooks/contexts/useDiagramContext';
import { RouteParams } from '../../../types/routes';

const sx = makeSxStyles({
    wrapper: {
        borderBottom: mainBorder,
        padding: 1,
    },
    link: {
        cursor: 'pointer',
    },
});

const activeClass = 'active';

export const DiagramBreadcrumbs = observer((): JSX.Element => {
    const { dataset, subLevel, activeSlice } = useDiagramContext();
    const { id, subLevelId, sliceId } = useParams<RouteParams>();
    const { search } = useLocation();
    const sliceTitle =
        sliceId && subLevel ? dataset?.subSlices[subLevel.id][activeSlice] : dataset?.slices[activeSlice];

    return (
        <React.Fragment>
            {(sliceId || subLevelId) && (
                <Breadcrumbs sx={sx.wrapper}>
                    <BreadcrumbsLink href={generatePath(clientRoute.dataset, { id }) + search} sx={sx.link}>
                        Радар
                    </BreadcrumbsLink>
                    {subLevel && (
                        <BreadcrumbsLink
                            href={generatePath(clientRoute.subLevel, { id, subLevelId }) + search}
                            {...(sliceId ? { sx: sx.link } : { className: activeClass })}
                        >
                            {subLevel.title}
                        </BreadcrumbsLink>
                    )}
                    {sliceId && <BreadcrumbsLink className={activeClass}>{sliceTitle}</BreadcrumbsLink>}
                </Breadcrumbs>
            )}
        </React.Fragment>
    );
});
