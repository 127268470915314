import { Grid, Link } from '@mui/material';
import { grey, makeSxStyles, SimilarObjects } from '@platform/front-ui';
import { ErrorBoundary } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useRadarContext } from '../../../../hooks/contexts/useRadarContext';
import { LinkIcon } from '../../../../resources/images/icons/Link';
import { ColorsMap } from '../../../../types/filters';
import { getColorMap } from '../../../../utils/dataset';
import { ObjectInfoTable } from './ObjectInfoTable';

const sx = makeSxStyles({
    title: {
        color: grey[900],

        '&:hover': {
            color: grey[900],
        },

        '& svg': {
            marginLeft: 0.5,
        },
    },
    contentWrapper: {
        position: 'relative',
    },
    contentInner: {
        position: 'absolute',
        top: '16px',
        right: 0,
        bottom: 0,
        left: '16px',
        paddingRight: 2,
        overflow: 'hidden auto',
    },
});

export const ObjectInfo = observer((): JSX.Element => {
    const { constants, activeDataset, dataset } = useRadarContext();
    const types = dataset?.types;

    const [colorsMap, setColorsMap] = useState<ColorsMap>({});

    useEffect(() => {
        setColorsMap(getColorMap(types, constants));
    }, [constants, types]);

    if (!activeDataset) {
        return <React.Fragment />;
    }

    const { similar } = activeDataset;

    const similarObjects = similar?.similarObjects || [];

    return (
        <Grid container direction="column" spacing={2} wrap="nowrap" height="100%">
            <Grid item>
                <Link href={activeDataset.link} target="_blank" rel="noreferrer" variant="h4" sx={sx.title}>
                    {activeDataset.name}
                    <LinkIcon />
                </Link>
            </Grid>

            <Grid item flexGrow={1} sx={sx.contentWrapper}>
                <Grid container direction="column" spacing={2} wrap="nowrap" sx={sx.contentInner}>
                    <Grid item>
                        <ObjectInfoTable colorsMap={colorsMap} />
                    </Grid>

                    <Grid item>
                        {/*На случай если exel файл заполнили неправильно*/}
                        {/*// @ts-ignore*/}
                        <ErrorBoundary>
                            <SimilarObjects similarObjects={similarObjects} title={similar?.title} />
                        </ErrorBoundary>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});
