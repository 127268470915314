import { Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

export type LegendSectionProps = {
    title: string;
    content: JSX.Element;
};

export const LegendSection = observer((props: LegendSectionProps): JSX.Element => {
    const { title, content } = props;

    return (
        <Grid container item direction="column" spacing={1}>
            <Grid item>
                <Typography variant="body1">{title}</Typography>
            </Grid>

            {content}
        </Grid>
    );
});
