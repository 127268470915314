import UploadIcon from '@mui/icons-material/FileDownload';
import { Button } from '@mui/material';
import { useAntiDoubleClick } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { VisuallyHiddenInput } from './VisuallyHiddenInput';

const uploadIcon = <UploadIcon />;

export type FileUploadProps = {
    onUpload: React.ChangeEventHandler;
};

export const FileUpload = observer((props: FileUploadProps): JSX.Element => {
    const { onUpload } = props;
    const [isUploading, endIcon, uploadHandler] = useAntiDoubleClick(onUpload);

    return (
        <Button
            component="label"
            variant="outlined"
            color="secondary"
            disabled={isUploading}
            startIcon={uploadIcon}
            endIcon={endIcon}
        >
            Загрузить
            <VisuallyHiddenInput type="file" onChange={uploadHandler} />
        </Button>
    );
});
