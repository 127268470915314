import { AppContentContainer, AppWrapper, Footer, Header } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Route } from 'react-router-dom';
import { clientRoute } from './clientRoute';
import { Notification } from './components/notification/Notification';
import { useStore } from './hooks/contexts/useStore';
import { MainPage } from './pages/main/MainPage';

export const App = observer((): JSX.Element => {
    const { isFullscreen } = useStore();

    return (
        <AppWrapper>
            <Header />
            <AppContentContainer>
                {/* @ts-ignore*/}
                <Route
                    path={[
                        clientRoute.subLevelSlice,
                        clientRoute.subLevel,
                        clientRoute.slice,
                        clientRoute.dataset,
                        clientRoute.root,
                    ]}
                >
                    <MainPage />
                </Route>
            </AppContentContainer>
            {/* Скрываем из-за zIndex (Мешает zIndex модалки похожих связей) */}
            {!isFullscreen && <Footer />}

            <Notification />
        </AppWrapper>
    );
});
