import { frontUi_ru } from '@platform/front-ui';
import { recordToLang } from '@platform/front-utils';
import { commonLocalesRu } from './common';

const localesRu: Record<string, string> = {
    ...recordToLang(
        {
            productTitle: 'ТОТ Радар',
            common: commonLocalesRu,
            frontUi: frontUi_ru,
        },
        false,
    ),
};

export default localesRu;
