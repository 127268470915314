import { makeSxStyles } from '@platform/front-ui';

export const filtersSx = makeSxStyles({
    container: {
        padding: '8px',
        paddingTop: '4px',
    },
    button: {
        marginLeft: 16,
    },
    item: {
        marginRight: 24,
        maxWidth: 320,
        whiteSpace: 'nowrap',
    },
    select: {
        padding: '0 6px',
    },
    dropdownTitleWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',

        'div:first-child': {
            marginBottom: 0,
        },
    },
    dot: {
        borderRadius: 8,
        display: 'inline-block',
        height: 8,
        marginRight: '4px',
        marginBottom: '1px',
        width: 8,
    },
});
