import { Grid } from '@mui/material';
import { grey, makeSxStyles, Typography } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';

const sx = makeSxStyles({
    inner: {
        paddingX: 3.25,
    },
    message: {
        color: grey[400],
    },
});

export const EmptyObjectInfo = observer((): JSX.Element => {
    return (
        <Grid container direction="column" justifyContent="center" height="100%">
            <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={1}
                sx={sx.inner}
            >
                <Grid item>
                    <Typography component="h2" variant="button">
                        Объект&nbsp;рассмотрения
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption" textAlign="center" sx={sx.message}>
                        Для отображения детальной информации, выберите объект рассмотрения в центральной части
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
});
