import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DiagramModel } from '../models/DiagramModel';
import { RadarModel } from '../models/RadarModel';
import { RouteParams } from '../types/routes';
import { SearchParam } from '../types/searchParam';
import { RadarView } from '../types/settings';
import { getURLSearchParams } from '../utils/searchParams';
import { useStore } from './contexts/useStore';

export const useDiagramRouteStates = (diagramModel: DiagramModel, radarModel: RadarModel): void => {
    const { view, setView } = useStore();
    const { dataset, onSubLevelChange, dropScaleLevel, setActiveSlice } = diagramModel;
    const { handleNewActiveDataset, handleNewActiveSimilarLine } = radarModel;
    const { id, subLevelId = '', sliceId = '' } = useParams<RouteParams>();
    const searchParams = getURLSearchParams();
    const activePointIdParam = searchParams.get(SearchParam.activePointId);
    const viewParam = searchParams.get(SearchParam.view) as RadarView | null;
    const startingPointIdParam = searchParams.get(SearchParam.startingPointId);
    const endingPointIdParam = searchParams.get(SearchParam.endingPointId);

    useEffect(() => {
        dropScaleLevel();
    }, [id, subLevelId, sliceId, view]);

    useEffect(() => {
        dataset && onSubLevelChange(subLevelId ? Number(subLevelId) : null);
    }, [dataset, subLevelId]);

    useEffect(() => {
        dataset && setActiveSlice(sliceId ? Number(sliceId) : -1);
    }, [dataset, sliceId]);

    useEffect(() => {
        dataset && handleNewActiveDataset(activePointIdParam || '');
    }, [dataset, activePointIdParam]);

    useEffect(() => {
        dataset && id && setView(id, viewParam);
    }, [dataset, id, viewParam]);

    useEffect(() => {
        dataset && handleNewActiveSimilarLine(startingPointIdParam || '', endingPointIdParam || '');
    }, [dataset, startingPointIdParam, endingPointIdParam]);
};
