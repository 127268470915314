import { Grid } from '@mui/material';
import { EllipsisBox, makeSxStyles, Typography } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { FileForm } from '../../components/file-form/FileForm';
import { Filters } from '../../components/filters/Filters';
import { mainBorder } from '../../constants/styles';
import { RadarContext } from '../../contexts/RadarContext';
import { useStore } from '../../hooks/contexts/useStore';
import { RadarModel } from '../../models/RadarModel';
import { MainContent } from './components/MainContent';

const sx = makeSxStyles({
    mainBlock: {
        border: mainBorder,
    },
    title: {
        wordBreak: 'break-word',
    },
    mainContentWrapper: {
        maxWidth: '100% !important',
    },
});

export const MainPage = observer((): JSX.Element => {
    const rootStore = useStore();

    const model = useMemo(() => new RadarModel(rootStore), [rootStore]);
    const { manifest } = model;

    return (
        <RadarContext.Provider value={model}>
            <Grid container direction="column" height="100%" wrap="nowrap" pt={2} px={2}>
                <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                    spacing={1}
                    mb={2}
                >
                    <Grid item flexGrow={1}>
                        <Typography component="h1" variant="h4" sx={sx.title}>
                            <EllipsisBox text={manifest?.name || ''} correctLength={100} />
                        </Typography>
                    </Grid>

                    <Grid item>
                        <FileForm />
                    </Grid>
                </Grid>

                <Grid container item direction="column" flexGrow={1} sx={sx.mainBlock}>
                    <Filters />

                    <Grid item flexGrow={1} sx={sx.mainContentWrapper}>
                        <MainContent />
                    </Grid>
                </Grid>
            </Grid>
        </RadarContext.Provider>
    );
});
