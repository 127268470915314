import { Grid, Link, Typography } from '@mui/material';
import { grey, makeSxStyles, SimilarObjectCoincidenceInfo } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SimilarObjectsItem } from '../../../../types/dataset';

export type SimilarObjectsItemInfoProps = {
    similarObjectsItem: SimilarObjectsItem;
    isFirstSource?: boolean;
};

const sx = makeSxStyles({
    subtitle: {
        color: grey[400],
    },
});

const getSourceSubtitle = (isFirstSource = false): string => `Объект ${isFirstSource ? 'А' : ' Б'}`;

export const SimilarObjectsItemInfo = observer((props: SimilarObjectsItemInfoProps): JSX.Element => {
    const { similarObjectsItem, isFirstSource } = props;
    const { title, similarObject } = similarObjectsItem;
    const { label, link } = similarObject;
    const { label: linkLabel, url } = link;

    const currentObjectSubtitle = getSourceSubtitle(isFirstSource);
    const similarObjectSubtitle = getSourceSubtitle(!isFirstSource);

    return (
        <Grid container direction="column" spacing={2} wrap="nowrap" height="100%">
            <Grid item>
                <Typography variant="body1" sx={sx.subtitle}>
                    {currentObjectSubtitle}
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant="body2">{title}</Typography>
            </Grid>

            <Grid container item direction="column" spacing={2}>
                <Grid item>
                    <Link href={url} target="_blank" mb={0.5}>
                        {linkLabel}
                    </Link>

                    <Typography variant="body1" sx={sx.subtitle}>
                        ({similarObjectSubtitle})
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="body1">{label}</Typography>
                </Grid>

                <SimilarObjectCoincidenceInfo similarObject={similarObject} />
            </Grid>
        </Grid>
    );
});
