import '@platform/front-ui/lib/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import 'setimmediate';
import { apiConfigs } from './apiConfigs';
import './index.css';
import { env, features, history, locales, palette } from './initRootProps';
import { Root } from './Root';

ReactDOM.render(
    <Root
        env={env}
        features={features}
        history={history}
        locales={locales}
        palette={palette}
        apiConfigs={apiConfigs}
    />,
    document.getElementById('root'),
);
