import { Box, Grid } from '@mui/material';
import { SxStyle } from '@platform/front-types';
import { grey, mergeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';

export type ItemWithIndicatorProps = {
    title: string;
    fontSize?: number;
    color?: string;
};

const indicatorSx: SxStyle = {
    borderRadius: '1em',
    display: 'inline-block',
    height: '1em',
    width: '1em',
};

export const ItemWithIndicator = observer((props: ItemWithIndicatorProps): JSX.Element => {
    const { title, fontSize = 8, color = grey[500] } = props;

    const sx = mergeSxStyles(indicatorSx, {
        fontSize,
        backgroundColor: color,
    });

    return (
        <Grid item container wrap="nowrap" alignItems="center" spacing={1}>
            <Grid item>
                <Box sx={sx} />
            </Grid>

            <Grid item>{title}</Grid>
        </Grid>
    );
});
