import { Theme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { RootProps, RootStoreProps, TotIntlProvider } from '@platform/front-core';
import { LocalizedMessages } from '@platform/front-types';
import { createTotTheme } from '@platform/front-ui';
import { CoreStoreContext } from '@platform/front-utils';
import { FeaturesProvider } from 'feature-toggle-jsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Router } from 'react-router-dom';
import { App } from './App';
import { StoreContext } from './contexts/StoreContext';
import { useStore } from './hooks/contexts/useStore';
import { RootStore } from './stores/RootStore';
import { SearchParam } from './types/searchParam';
import { RadarView } from './types/settings';
import { getSearchParam } from './utils/searchParams';

const ruDefault = require(`./customization/default/locales/ru`).default || {};
const enDefault = require(`./customization/default/locales/ru`).default || {};

const RootUnwrapped = observer((): JSX.Element => {
    const theme = createTotTheme();

    return (
        <ThemeProvider theme={theme as Theme}>
            <TotIntlProvider>
                <App />
            </TotIntlProvider>
        </ThemeProvider>
    );
});

const RootWithFeatures = observer((): JSX.Element => {
    const { features } = useStore();
    return (
        <FeaturesProvider features={features as any}>
            <RootUnwrapped />
        </FeaturesProvider>
    );
});

export const RootWithStore = observer((props: RootStoreProps): JSX.Element => {
    const view = getSearchParam(SearchParam.view) as RadarView | null;
    const store = new RootStore({
        ...props,
        view: view || undefined,
    });

    return (
        // @ts-ignore (Должно пофиксится после перехода на 20ую ноду)
        <Router history={store.coreRootStore.history}>
            <StoreContext.Provider value={store}>
                <CoreStoreContext.Provider value={store.coreRootStore}>
                    <RootWithFeatures />
                </CoreStoreContext.Provider>
            </StoreContext.Provider>
        </Router>
    );
});

export const Root = observer((props: RootProps): JSX.Element => {
    const { palette, locales: propsLocales } = props;
    const theme = createTotTheme(palette);
    const locales: LocalizedMessages = {
        ru: { ...ruDefault, ...propsLocales?.ru },
        en: { ...enDefault, ...propsLocales?.en },
    };

    return <RootWithStore {...props} theme={theme} locales={locales} />;
});
