import { RadarView } from '../types/settings';

export const diagramClasses = {
    svg: 'svg',
    similarSvg: 'similarSvg',
    svgWrapper: 'svgWrapper',
    active: 'active',
    inactive: 'inactive',
};

export const diagramFileNames: Record<RadarView, string> = {
    default: 'items',
    similar: 'items-similar',
};
