import TuneIcon from '@mui/icons-material/Tune';
import { ToggleButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useFullscreenOnOpenAction } from '../../../../hooks/useFullscreenOnOpenAction';
import { SettingsGroup } from '../../../../types/settings';
import { SimilarFiltersPopper } from './SimilarFiltersPopper';

export const SimilarFiltersButton = observer((): JSX.Element => {
    const [isSimilarFiltersOpen, , closeSimilarFilters, setSimilarFiltersOpen] = useFlag();
    useFullscreenOnOpenAction(closeSimilarFilters);

    const relationsAnchorEl = useRef<HTMLButtonElement>(null);

    const onSimilarFiltersClick = (): void => {
        setSimilarFiltersOpen((isSimilarSettingsOpenState) => !isSimilarSettingsOpenState);
    };

    return (
        <React.Fragment>
            <Tooltip title="Настроить отображение связей">
                <ToggleButton
                    className="MuiToggleButtonGroup-grouped"
                    onClick={onSimilarFiltersClick}
                    selected={isSimilarFiltersOpen}
                    value={SettingsGroup.similar}
                    ref={relationsAnchorEl}
                >
                    <TuneIcon />
                </ToggleButton>
            </Tooltip>

            <SimilarFiltersPopper
                open={isSimilarFiltersOpen}
                onCancel={closeSimilarFilters}
                anchorEl={relationsAnchorEl}
            />
        </React.Fragment>
    );
});
