import { RefObject, useEffect } from 'react';
import { SvgProps } from '../components/diagram/components/svg/Svg';
import { diagramClasses } from '../constants/diagram';
import { SvgModel } from '../models/svg/SvgModel';
import { useRadarContext } from './contexts/useRadarContext';

export const useSvgModelSetters = (props: SvgProps, model: SvgModel, wrapperRef: RefObject<HTMLDivElement>): void => {
    const { id, fileName, diameter, padding } = props;

    const { handlePointClick, handleSimilarLineClick } = useRadarContext();
    const {
        isLoading,
        setWidth,
        setId,
        setFileName,
        setDiameter,
        setPadding,
        setOnPointClick,
        setOnSimilarLineClick,
        setHolderElement,
    } = model;

    useEffect(() => {
        setId(id);
    }, [id]);

    useEffect(() => {
        setFileName(fileName);
    }, [fileName]);

    useEffect(() => {
        setDiameter(diameter);
    }, [diameter]);

    useEffect(() => {
        setPadding(padding);
    }, [padding]);

    useEffect(() => {
        setOnPointClick(handlePointClick);
    }, [handlePointClick]);

    useEffect(() => {
        setOnSimilarLineClick(handleSimilarLineClick);
    }, [handleSimilarLineClick]);

    useEffect(() => {
        const holderElement = wrapperRef.current?.querySelector(`.${diagramClasses.svg}`) as HTMLDivElement;
        setHolderElement(holderElement);
        setWidth();
    }, [isLoading, wrapperRef.current]);
};
