export enum MessageType {
    error = 'error',
    success = 'success',
}

export type Message = {
    text: string;
    type: MessageType;
};

export type ResponseMessage = {
    message: string;
};
