export enum SearchParam {
    view = 'view',
    activePointId = 'activePointId',
    startingPointId = 'startingPointId',
    endingPointId = 'endingPointId',
}

export type SearchParamProperty = {
    property: SearchParam;
    value: string;
};
