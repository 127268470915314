import { grey } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { ImperativePanelHandle, Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Diagram } from '../../../components/diagram/Diagram';
import { SidebarInfoToggleButton } from '../../../components/sidebar-info/components/SidebarInfoToggleButton';
import { SidebarInfo } from '../../../components/sidebar-info/SidebarInfo';
import { zIndexes } from '../../../constants/zIndexes';
import { useRadarContext } from '../../../hooks/contexts/useRadarContext';
import { useStore } from '../../../hooks/contexts/useStore';
import { UseMainContentSizes } from '../../../hooks/useMainContentSizes';

const panelResizeStyles: CSSProperties = {
    width: '10px',
    marginRight: '-5px',
    marginLeft: '-5px',
    position: 'relative',
    zIndex: zIndexes.resizePanel,
};

const panelGroupStyles: CSSProperties = {
    position: 'fixed',
    inset: 0,
    zIndex: zIndexes.fullScreen,
    background: grey[0],
};

export const MainContent = observer((): JSX.Element => {
    const { isFullscreen } = useStore();
    const { isSidebarOpen, setIsSidebarOpen, handleSidebarToggle } = useRadarContext();

    const ref = useRef<ImperativePanelHandle>(null);
    const defaultSidebarSize = UseMainContentSizes(ref.current);
    const [isCollapsed, collapse, expand] = useFlag();
    const [isDragging, setIsDragging] = useState<boolean>();

    useEffect(() => {
        const panel = ref.current;

        if (panel) {
            panel.collapse();
            panel.expand();
        }
    }, [ref.current]);

    useEffect(() => {
        const panel = ref.current;

        if (panel) {
            if (isSidebarOpen) {
                isCollapsed && panel.expand();
            } else {
                !isCollapsed && panel.collapse();
            }
        }
    }, [isSidebarOpen, isCollapsed]);

    const onCollapse = (): void => {
        setIsSidebarOpen(false);
        collapse();
    };

    const onExtend = (): void => {
        setIsSidebarOpen(true);
        expand();
    };

    const panelAnimationStyles: CSSProperties = {
        transition: isDragging ? 'none' : 'all 0.5s ease-in-out',
    };

    return (
        <PanelGroup {...(isFullscreen && { style: panelGroupStyles })} direction="horizontal">
            <Panel style={panelAnimationStyles} minSize={30}>
                <Diagram />
            </Panel>

            <PanelResizeHandle style={panelResizeStyles} onDragging={setIsDragging}>
                <SidebarInfoToggleButton open={isSidebarOpen} onToggle={handleSidebarToggle} />
            </PanelResizeHandle>

            <Panel
                style={panelAnimationStyles}
                defaultSize={defaultSidebarSize}
                minSize={20}
                collapsible
                onCollapse={onCollapse}
                onExpand={onExtend}
                ref={ref}
            >
                <SidebarInfo />
            </Panel>
        </PanelGroup>
    );
});
