import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { Checkbox, Grid, IconButton, MenuItem, Select, SelectChangeEvent, SelectProps, Tooltip } from '@mui/material';
import { EllipsisBox, makeSxStyles, mergeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { MouseEvent } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { FileFormModel } from '../../../models/FileFormModel';
import { getSelectedFile } from '../../../utils/fileUtils';

export type FileSelectProps = {
    model: FileFormModel;
};

const sx = makeSxStyles({
    select: {
        minWidth: '230px',
        maxWidth: '350px',
        padding: 0,

        '[class*="MuiSelect-select"]': {
            padding: '6px 16px !important',
            paddingRight: '32px !important',

            '[class*="MuiBox-root"]': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
        },
    },
    emptyValueItem: {
        height: 0,
        padding: 0,
    },
    withoutOptionsItem: {
        backgroundColor: 'transparent !important',
        pointerEvents: 'none',
    },
    checkIcon: {
        fontSize: '12px',
    },
    actionIcon: {
        fontSize: '14px',
    },
    menuItem: {
        '&:hover [class*="MuiCheckbox"]': {
            opacity: 1,
        },
    },
    defaultCheckbox: {
        opacity: 0,
    },
    checkedDefaultCheckbox: {
        opacity: 1,
    },
    listWrapper: {
        '& > [class*="MuiPaper-root"]': {
            scrollbarWidth: 'thin', // Для ff
        },
    },
    list: {
        width: '480px !important',
    },
});

const menuProps: SelectProps['MenuProps'] = {
    MenuListProps: {
        sx: sx.list,
    },
    sx: sx.listWrapper,
};

const emptyValue = 'empty';

export const FileSelect = observer((props: FileSelectProps): JSX.Element => {
    const { model } = props;
    const { files, active, setDefaultFile, downloadFile, setToRemove } = model;
    const value = active?.id || '';
    const correctValue = value || emptyValue;
    const history = useHistory();

    const changeHandler = (event: SelectChangeEvent): void => {
        const id = event.target.value;
        history.push(generatePath(clientRoute.dataset, { id }));
    };

    const options: React.ReactNode = files.length ? (
        files.map((file): JSX.Element => {
            const { id, name, isByDefault = false } = file;
            const isSelected = id === value;

            const handleDefaultCheck = (event: MouseEvent<HTMLButtonElement>): void => {
                event.stopPropagation();
                setDefaultFile(id);
            };
            const handleDownload = (event: MouseEvent<HTMLButtonElement>): void => {
                event.stopPropagation();
                downloadFile(file);
            };
            const handleRemove = (event: MouseEvent<HTMLButtonElement>): void => {
                event.stopPropagation();
                setToRemove(file);
            };

            const defaultCheckboxSx = mergeSxStyles(sx.defaultCheckbox, isByDefault ? sx.checkedDefaultCheckbox : {});

            return (
                <MenuItem value={id} key={id} sx={sx.menuItem}>
                    <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" spacing={2}>
                        <Grid item container wrap="nowrap" alignItems="center" spacing={1}>
                            <Grid item minWidth="20px">
                                {isSelected && <CheckIcon sx={sx.checkIcon} />}
                            </Grid>

                            <Grid item>
                                <EllipsisBox text={name} correctLength={40} />
                            </Grid>
                        </Grid>

                        <Grid item container wrap="nowrap" justifyContent="flex-end" alignItems="center">
                            <Grid item>
                                <Tooltip title="Отображать по умолчанию">
                                    <Checkbox
                                        checked={isByDefault}
                                        onClick={handleDefaultCheck}
                                        sx={defaultCheckboxSx}
                                        size="small"
                                    />
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                <IconButton onClick={handleDownload} size="small">
                                    <DownloadIcon sx={sx.actionIcon} />
                                </IconButton>
                            </Grid>

                            <Grid item>
                                <IconButton onClick={handleRemove} size="small">
                                    <DeleteIcon sx={sx.actionIcon} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </MenuItem>
            );
        })
    ) : (
        <MenuItem sx={sx.withoutOptionsItem} tabIndex={-1}>
            Нет доступных датасетов
        </MenuItem>
    );

    const renderValue = (id: string): string => {
        return id === emptyValue ? 'Выбрать датасет' : getSelectedFile(id, files).name;
    };

    return (
        <Select
            value={correctValue}
            size="small"
            color="secondary"
            onChange={changeHandler}
            renderValue={renderValue}
            MenuProps={menuProps}
            sx={sx.select}
        >
            <MenuItem disabled value={emptyValue} sx={sx.emptyValueItem}></MenuItem>
            {options}
        </Select>
    );
});
