import { CoreFeatures } from '@platform/front-core';
import radarLogo from '../../resources/images/radar-logo.svg';

const features: CoreFeatures = {
    headerLogo: {
        isEnabled: true,
        locales: {
            ru: {
                image: radarLogo,
                alt: 'Radar логотип',
                style: { height: '28px', marginBottom: '-4px' },
            },
        },
    },
    helpIcon: true,
    phoneNumber: true,
    isSingleLocale: true,
};

export default features;
