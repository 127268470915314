import { ApiConfigs as CoreApiConfigs } from '@platform/front-core';
import { AxiosRequestConfig } from 'axios';
import { RadarView } from './types/settings';

export class ApiConfigs extends CoreApiConfigs {
    getDiagramInfo: AxiosRequestConfig = {
        url: '/api/diagram/info',
        method: 'GET',
    };

    getFiles: AxiosRequestConfig = {
        url: '/api/files',
        method: 'GET',
    };

    getDiagram = (id: string, fileName: string, view: RadarView): AxiosRequestConfig => {
        return {
            url: `/api/diagram/${id}/${fileName}`,
            method: 'GET',
            params: {
                view,
            },
        };
    };

    getDataset = (id: string): AxiosRequestConfig => {
        return {
            url: `/api/diagram/dataset/${id}`,
            method: 'GET',
        };
    };

    getFile = (id: string, fileName: string): AxiosRequestConfig => {
        return {
            url: `/files/${id}/${fileName}`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    uploadFile = (data: FormData): AxiosRequestConfig => {
        return {
            url: '/api/files',
            method: 'POST',
            data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
    };

    setDefaultFile = (fileId: string): AxiosRequestConfig => {
        return {
            url: '/api/files/default',
            method: 'POST',
            params: {
                fileId,
            },
        };
    };

    removeFile = (id: string): AxiosRequestConfig => {
        return {
            url: `/api/files/${id}`,
            method: 'DELETE',
        };
    };
}

export const apiConfigs = new ApiConfigs();
