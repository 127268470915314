import { debounce } from '@mui/material';
import { CodeTitle } from '@platform/front-types';
import { action, computed, makeObservable, observable } from 'mobx';
import { ExtendedDataset } from '../types/dataset';
import { Constants } from '../types/diagram';
import { ColorsMap, DiagramFilters } from '../types/filters';
import { getColorMap } from '../utils/dataset';

export const factorOptions: CodeTitle[] = [
    {
        title: 'Низкий',
        code: 'низкий',
    },
    {
        title: 'Средний',
        code: 'средний',
    },
    {
        title: 'Высокий',
        code: 'высокий',
    },
];

export const filtersModelObservables = {
    dataset: observable,
    colorsMap: observable,
    searchOptions: observable,
    fieldValues: observable,

    initialValues: computed,

    handleSearch: action.bound,
    updateColorsMap: action.bound,

    dropFieldValues: action.bound,
    setSearchOptions: action.bound,
    setColorsMap: action.bound,
    setDataset: action.bound,
    setFieldValues: action.bound,
};

export class FiltersModel {
    dataset: ExtendedDataset | null = null;
    colorsMap: ColorsMap = {};
    searchOptions: CodeTitle[] = [];
    fieldValues: DiagramFilters | null = null;

    constructor() {
        makeObservable(this, filtersModelObservables);
    }

    get initialValues(): DiagramFilters {
        return {
            search: null,
            types:
                this.dataset?.types.map((item) => ({
                    title: item,
                    code: item.trim().toLowerCase(),
                })) || [],
            factors: factorOptions,
        };
    }

    handleSearch(value: string): void {
        if (!value) {
            this.setSearchOptions([]);
            return;
        }

        const newSearchOptions: CodeTitle[] = this.dataset
            ? Object.values(this.dataset.items)
                  .filter((item) =>
                      `${item.name}${item.other.map((other) => other.value).join('')}`
                          .toLowerCase()
                          .includes(value.trim().toLowerCase()),
                  )
                  .map((item) => ({ title: item.name, code: item.id }))
            : [];

        this.setSearchOptions(newSearchOptions);
    }

    updateColorsMap(constants: Constants | null): void {
        const newColorsMap: ColorsMap = getColorMap(this.dataset?.types, constants);
        this.setColorsMap(newColorsMap);
    }

    dropFieldValues(): void {
        this.fieldValues = null;
    }

    setSearchOptions(searchOptions: CodeTitle[]): void {
        this.searchOptions = searchOptions;
    }

    setColorsMap(colorsMap: ColorsMap): void {
        this.colorsMap = colorsMap;
    }

    setDataset(dataset: ExtendedDataset | null): void {
        this.dataset = dataset;

        if (dataset === null) {
            this.dropFieldValues();
        }
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    setFieldValues = debounce((fieldValues: DiagramFilters | null): void => {
        this.fieldValues = fieldValues;
    }, 200);
}
