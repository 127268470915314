import { useEffect } from 'react';
import { useStore } from './contexts/useStore';

export const useFullscreenOnOpenAction = (onOpenAction: VoidFunction): void => {
    const { isFullscreen } = useStore();

    useEffect(() => {
        isFullscreen && onOpenAction();
    }, [isFullscreen]);
};
