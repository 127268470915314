import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { Dataset } from '../types/dataset';
import { Constants } from '../types/diagram';
import { Manifest } from '../types/manifest';
import { ResponseMessage } from '../types/message';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

export const diagramStoreObservables = {
    rootStore: observable,
    api: observable,
    history: observable,

    getDiagram: action.bound,
    getDiagramInfo: action.bound,
    getDataset: action.bound,
    getFiles: action.bound,
    setDefaultFile: action.bound,
    getFile: action.bound,
    uploadFile: action.bound,
    removeFile: action.bound,
};

export class DiagramStore {
    protected rootStore: RootStore;
    protected api: ApiStore;
    protected history: History;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
        this.history = rootStore.coreRootStore.history;
        makeObservable(this, diagramStoreObservables);
    }

    getDiagram(id: string, fileName: string): Promise<string> {
        const { view } = this.rootStore;
        return this.api
            .client(apiConfigs.getDiagram(id, fileName, view))
            .then((r) => r.data)
            .catch((error) => {
                this.history.push(clientRoute.selectDataset);
                return Promise.reject(error);
            });
    }

    getDiagramInfo(): Promise<Constants> {
        return this.api.client(apiConfigs.getDiagramInfo).then((r) => r.data);
    }

    getDataset(id: string): Promise<Dataset> {
        return this.api.client(apiConfigs.getDataset(id)).then((r) => r.data);
    }

    getFiles(): Promise<Manifest[]> {
        return this.api.client(apiConfigs.getFiles).then((r) => r.data);
    }

    setDefaultFile(id: string): Promise<Manifest[]> {
        return this.api.client(apiConfigs.setDefaultFile(id)).then((r) => r.data);
    }

    getFile(id: string, fileName: string): Promise<BlobPart> {
        return this.api.client(apiConfigs.getFile(id, fileName)).then((r) => r.data);
    }

    uploadFile(file: FormData): Promise<Manifest> {
        return this.api.client(apiConfigs.uploadFile(file)).then((r) => r.data);
    }

    removeFile(id: string): Promise<ResponseMessage> {
        return this.api.client(apiConfigs.removeFile(id)).then((r) => r.data);
    }
}
