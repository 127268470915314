import { DatasetItem, ExtendedDataset } from '../types/dataset';
import { Constants } from '../types/diagram';
import { ColorsMap } from '../types/filters';

export const mapDataSetItemToExtendedDatasetItem = (items: DatasetItem[]): ExtendedDataset['items'] => {
    return items.reduce((result, current) => ({ ...result, [current.id]: current }), {});
};

export const getColorMap = (types?: string[], constants?: Constants | null): ColorsMap => {
    return (
        types?.reduce(
            (result, current, i) => ({ ...result, [current.trim().toLowerCase()]: constants?.colors[i] || '#000' }),
            {},
        ) || {}
    );
};
