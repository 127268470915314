import { Box } from '@mui/material';
import { grey, makeSxStyles, mergeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useEffect, useRef } from 'react';
import { zIndexes } from '../constants/zIndexes';
import { TooltipParams } from '../types/diagram';

export type TooltipProps = {
    params: TooltipParams | null;
};

const sxStyles = makeSxStyles({
    tooltip: {
        backgroundColor: grey[0],
        borderRadius: '3px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
        maxWidth: '340px',
        padding: '8px 12px',
        position: 'fixed',
        zIndex: zIndexes.tooltip,
    },
    top: {
        marginTop: '-6px',
        transform: 'translateX(-50%) translateY(-100%)',

        '&:after': {
            borderColor: `${grey[0]} transparent transparent transparent`,
            borderStyle: 'solid',
            borderWidth: '4px 6px 0 6px',
            content: '""',
            display: 'block',
            height: 0,
            left: '50%',
            position: 'absolute',
            transform: 'translateX(-50%) translateY(100%)',
            bottom: 0,
            width: 0,
        },
    },
    bottom: {
        transform: 'translateX(-50%) translateY(16px)',

        '&:after': {
            borderColor: `transparent transparent ${grey[0]} transparent`,
            borderStyle: 'solid',
            borderWidth: '0 6px 4px 6px',
            content: '""',
            display: 'block',
            height: 0,
            left: '50%',
            position: 'absolute',
            top: 0,
            transform: 'translateX(-50%) translateY(-100%)',
            width: 0,
        },
    },
});

export const Tooltip = observer((props: TooltipProps): JSX.Element => {
    const { params } = props;

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current && ref.current.parentElement) {
            const parentRect = ref.current.parentElement.getBoundingClientRect();
            const popupRect = ref.current.getBoundingClientRect();
            if (popupRect.bottom > parentRect.bottom) {
                ref.current.style.top = `${popupRect.top - popupRect.height - 40}px`;
            }
        }
    }, [params]);

    const sx = params && mergeSxStyles(sxStyles.tooltip, sxStyles[params.type]);
    const style: CSSProperties = params
        ? {
              left: params.left,
              top: params.top,
          }
        : {};

    return (
        <React.Fragment>
            {params && (
                <Box sx={sx} style={style} ref={ref}>
                    {params.text}
                </Box>
            )}
        </React.Fragment>
    );
});
