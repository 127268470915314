import { SimilarObject } from '@platform/front-ui/lib/types/similarObjects';
import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';
import { diagramClasses } from '../constants/diagram';
import { DiagramStore } from '../stores/DiagramStore';
import { NotificationStore } from '../stores/NotificationStore';
import { RootStore } from '../stores/RootStore';
import { DatasetItem, ExtendedDataset, SimilarObjects, SimilarObjectsItem } from '../types/dataset';
import { Constants, Filter } from '../types/diagram';
import { SelectFilter } from '../types/filters';
import { Manifest } from '../types/manifest';
import { MessageType } from '../types/message';
import { SearchParam, SearchParamProperty } from '../types/searchParam';
import { mapDataSetItemToExtendedDatasetItem } from '../utils/dataset';
import {
    getSearchParam,
    removeSearchParam,
    removeSearchParams,
    setSearchParam,
    setSearchParams,
} from '../utils/searchParams';

export const radarModelObservables = {
    rootStore: observable,
    diagramStore: observable,
    notificationStore: observable,
    history: observable,

    manifest: observable,
    dataset: observable,
    constants: observable,
    filters: observable,
    activeDataset: observable,
    activeSimilarObjects: observable,
    isSidebarOpen: observable,

    loadConstants: action.bound,
    loadDataset: action.bound,
    getSimilarObjectInfo: action.bound,
    handleManifestChange: action.bound,
    handleFiltersChange: action.bound,
    handlePointClick: action.bound,
    handleSimilarLineClick: action.bound,
    handleSidebarToggle: action.bound,
    handleNewActiveDataset: action.bound,
    handleNewActiveSimilarLine: action.bound,
    addActiveClassToPoint: action.bound,
    addActiveClassToSimilarLine: action.bound,

    dropActiveSimilarObjects: action.bound,
    dropActivePointIdSearchParam: action.bound,
    dropActiveSimilarLineSearchParams: action.bound,
    setManifest: action.bound,
    setDataset: action.bound,
    setConstants: action.bound,
    setFilters: action.bound,
    setActiveDataset: action.bound,
    setActiveSimilarObjects: action.bound,
    setIsSidebarOpen: action.bound,
    setActivePointIdSearchParam: action.bound,
    setActiveSimilarLineSearchParams: action.bound,
};

const activeClass = diagramClasses.active;

export class RadarModel {
    protected rootStore: RootStore;
    protected diagramStore: DiagramStore;
    protected notificationStore: NotificationStore;
    protected history: History;

    manifest: Manifest | null = null;
    dataset: ExtendedDataset | null = null;
    constants: Constants | null = null;
    filters: Filter = { factors: [], types: [] };
    activeDataset: DatasetItem | null = null;
    activeSimilarObjects?: SimilarObjects | null = null;
    isSidebarOpen = true;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.diagramStore = rootStore.diagramStore;
        this.notificationStore = rootStore.notificationStore;
        this.history = rootStore.coreRootStore.history;
        makeObservable(this, radarModelObservables);
        this.loadConstants();
    }

    loadConstants(): void {
        this.diagramStore
            .getDiagramInfo()
            .then(this.setConstants)
            .catch(() => {
                this.notificationStore.add({
                    text: 'Не удалось загрузить информацию о диаграмме',
                    type: MessageType.error,
                });
            });
    }

    loadDataset(id: string): void {
        this.diagramStore.getDataset(id).then((dataset) => {
            this.setDataset({
                ...dataset,
                items: mapDataSetItemToExtendedDatasetItem(dataset.items),
            });
        });
    }

    getSimilarObjectInfo(objectId: string, similarObjectId: string): SimilarObjectsItem | undefined {
        const object: DatasetItem | undefined = this.dataset?.items[objectId];

        const similarObject: SimilarObject | undefined = object?.similar?.similarObjects.find((similarObject) => {
            return similarObject.id === similarObjectId;
        });

        if (object && similarObject) {
            return {
                title: object.name,
                similarObject,
            };
        }
        return;
    }

    handleManifestChange(value: Manifest | null): void {
        this.setManifest(value);
        this.dropActivePointIdSearchParam();
        this.dropActiveSimilarObjects();
        this.setDataset(null);

        if (value) {
            this.loadDataset(value.id);
        }
    }

    handleFiltersChange(field: SelectFilter, value: string[]): void {
        this.setFilters({ ...this.filters, [field]: value });
    }

    handlePointClick(pointId: string): void {
        this.dropActiveSimilarLineSearchParams();
        this.setActivePointIdSearchParam(pointId);
    }

    handleSimilarLineClick(target: SVGElement): void {
        this.dropActivePointIdSearchParam();
        const { startingPointId = '', endingPointId = '' } = target.dataset;
        this.setActiveSimilarLineSearchParams(startingPointId, endingPointId);
    }

    handleSidebarToggle(): void {
        this.setIsSidebarOpen(!this.isSidebarOpen);
    }

    handleNewActiveDataset(pointId: string): void {
        this.dropActiveSimilarObjects();

        const newActiveDataset = this.dataset?.items[pointId] || null;
        this.setActiveDataset(newActiveDataset);

        if (newActiveDataset) {
            this.setIsSidebarOpen(true);
        }
    }

    handleNewActiveSimilarLine(startingPointId: string, endingPointId: string): void {
        if (this.activeSimilarObjects) {
            const { firstObject, secondObject } = this.activeSimilarObjects;
            const isItemAlreadySelected =
                startingPointId === secondObject.similarObject.id && endingPointId === firstObject.similarObject.id;
            this.dropActiveSimilarObjects();

            // При клике на уже выбранную связь, сбрасываем выделение
            if (isItemAlreadySelected) {
                return;
            }
        } else {
            // На случай, если данные по схожим объектам не были заполнены
            this.dropActiveSimilarObjects();
        }

        // Подсветка активных элементов
        this.addActiveClassToPoint(startingPointId);
        this.addActiveClassToPoint(endingPointId);
        this.addActiveClassToSimilarLine(startingPointId, endingPointId);

        const firstObject = this.getSimilarObjectInfo(startingPointId, endingPointId);
        const secondObject = this.getSimilarObjectInfo(endingPointId, startingPointId);

        if (firstObject && secondObject) {
            const similarObjects: SimilarObjects = {
                firstObject,
                secondObject,
            };

            this.setActiveSimilarObjects(similarObjects);
            this.setIsSidebarOpen(true);
        }
    }

    addActiveClassToPoint(id: string): void {
        document.querySelector(`.point[data-id="${id}"]`)?.classList.add(activeClass);
    }

    addActiveClassToSimilarLine(startingPointId: string, endingPointId: string): void {
        document
            .querySelector(
                `.similar-line[data-starting-point-id="${startingPointId}"][data-ending-point-id="${endingPointId}"]`,
            )
            ?.classList.add(activeClass);
    }

    dropActiveSimilarObjects(): void {
        document.querySelector(`.similar-line.${activeClass}`)?.classList.remove(activeClass);
        document.querySelectorAll(`.point.${activeClass}`).forEach((element) => {
            element.classList.remove(activeClass);
        });

        this.activeSimilarObjects = null;
    }

    dropActivePointIdSearchParam(): void {
        this.setActivePointIdSearchParam(null);
    }

    dropActiveSimilarLineSearchParams(): void {
        this.setActiveSimilarLineSearchParams('', '');
    }

    setManifest(manifest: Manifest | null): void {
        this.manifest = manifest;
    }

    setDataset(dataset: ExtendedDataset | null): void {
        this.dataset = dataset;
    }

    setConstants(constants: Constants): void {
        this.constants = constants;
    }

    setFilters(filters: Filter): void {
        this.filters = filters;
    }

    setActiveDataset(activeDataset: DatasetItem | null): void {
        this.activeDataset = activeDataset;
    }

    setActiveSimilarObjects(activeSimilarObjects: SimilarObjects): void {
        this.activeSimilarObjects = activeSimilarObjects;
    }

    setIsSidebarOpen(isSidebarOpen: boolean): void {
        this.isSidebarOpen = isSidebarOpen;
    }

    setActivePointIdSearchParam(activePointId: string | null): void {
        if (this.dataset) {
            const isNewPointId = getSearchParam(SearchParam.activePointId) !== activePointId;

            let newSearchParams: string;
            if (isNewPointId && activePointId) {
                newSearchParams = setSearchParam(SearchParam.activePointId, activePointId);
            } else {
                newSearchParams = removeSearchParam(SearchParam.activePointId);
            }

            this.history.replace({ search: newSearchParams });
        }
    }

    setActiveSimilarLineSearchParams(startingPointId: string, endingPointId: string): void {
        if (this.dataset) {
            const isNewPointsIds =
                getSearchParam(SearchParam.startingPointId) !== startingPointId &&
                getSearchParam(SearchParam.endingPointId) !== endingPointId;

            let newSearchParams: string;
            if (isNewPointsIds && startingPointId && endingPointId) {
                const searchParams: SearchParamProperty[] = [
                    {
                        property: SearchParam.startingPointId,
                        value: startingPointId,
                    },
                    {
                        property: SearchParam.endingPointId,
                        value: endingPointId,
                    },
                ];
                newSearchParams = setSearchParams(searchParams);
            } else {
                newSearchParams = removeSearchParams([SearchParam.startingPointId, SearchParam.endingPointId]);
            }
            this.history.replace({ search: newSearchParams });
        }
    }
}
