import { grey, makeSxStylesWithProps } from '@platform/front-ui';
import { identity } from '@platform/front-utils';
import { diagramClasses } from '../../../../constants/diagram';
import { Filter } from '../../../../types/diagram';
import { SimilarFilters, SimilarRelationType } from '../../../../types/filters';

type SvgSxProps = {
    svgDomObject: Document;
    isDefaultView: boolean;
    activePointId: string;
    filters: Filter;
    similarFilters: SimilarFilters;
};

export const getSvgSx = makeSxStylesWithProps((props: SvgSxProps) => {
    const { svgDomObject, isDefaultView, activePointId, filters, similarFilters } = props;
    const { types, factors } = filters;

    const getAllFactorsSelector = (typeSelector: string): string => {
        return factors.map((factor) => `${typeSelector}[data-factor="${factor}"]`).join(', ');
    };

    const visiblePointsSelector = types
        .map((type) => {
            const typeSelector = `[data-typename="${type}"]`;
            return isDefaultView ? getAllFactorsSelector(typeSelector) : typeSelector;
        })
        .filter(identity)
        .join(', ');

    const visibleSimilarLinesSelector = Object.keys(similarFilters)
        .filter((similarType) => similarFilters[similarType as SimilarRelationType])
        .map((similarType) => `[data-similar-type="${similarType}"]`)
        .join(', &');

    const hiddenSimilarLinesSelector = visiblePointsSelector
        ? Array.from<HTMLElement>(svgDomObject.querySelectorAll(`.point:not(${visiblePointsSelector})`))
              .map((element) => {
                  const visibleElementId = (element.attributes as any)['data-id'].nodeValue;
                  return `&[data-starting-point-id="${visibleElementId}"], &[data-ending-point-id="${visibleElementId}"]`;
              })
              .join(', ')
        : '';

    return {
        wrapper: {
            position: 'relative',
            userSelect: 'none',
        },

        svg: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',

            '& svg': {
                height: '100%',
                width: 'auto',
                transition: 'viewbox 0.5s ease-in-out',

                '.point': {
                    display: 'none',

                    [`&[data-id="${activePointId}"], &.${diagramClasses.active}`]: {
                        stroke: grey[900],
                        strokeWidth: '3px !important',
                        fillOpacity: '1 !important',
                    },
                },

                // .point
                [`${visiblePointsSelector}`]: visiblePointsSelector && {
                    display: 'block',
                },

                '& path, & circle': {
                    transition: 'fill-opacity 0.3s ease-in-out',
                },

                '& g[data-type="slice"] path': {
                    fill: '#fff',
                    fillOpacity: 0,
                },

                [`& g.${diagramClasses.inactive}[data-type="slice"] path:not([data-type="subs"])`]: {
                    fillOpacity: 0.6,
                },

                [`& g[data-type="slice"] path:hover, & g[data-type="slice"].${diagramClasses.active} path`]: {
                    fillOpacity: 0,
                },

                '& g.slice-label': {
                    pointerEvents: 'bounding-box',

                    '& text': {
                        pointerEvents: 'none',
                    },

                    [`&.${diagramClasses.active}`]: {
                        cursor: 'pointer',

                        '& text': {
                            animation: '0.3s linear text',
                            animationFillMode: 'both',
                        },

                        '&:hover text': {
                            fontWeight: 700,
                        },
                    },
                },

                '& path[data-type="subs"]': {
                    cursor: 'pointer',
                    pointerEvents: 'stroke', // если оставить по-умолчанию, то будет срабатывать на область (часть окружности), а не на дугу
                    stroke: '#dee0e1',
                    transition: 'stroke 0.3s ease-in-out',

                    [`&.${diagramClasses.active}`]: {
                        animation: '0.3s linear stroke',
                        animationFillMode: 'both',
                    },
                },

                '& text': {
                    fontWeight: 400,
                    transition: 'font-weight 0.3s ease-in-out',

                    [`&.${diagramClasses.active}`]: {
                        fontWeight: 700,
                    },
                },

                '& .point': {
                    cursor: 'pointer',
                    fillOpacity: 0.6,

                    '&:hover': {
                        fillOpacity: 1,
                        stroke: grey[900],
                        strokeWidth: '2px',
                    },

                    [`&.${diagramClasses.active}`]: {
                        fillOpacity: 1,
                        stroke: grey[900],
                        strokeWidth: '3px',
                    },
                },

                '& .similar-line': {
                    [hiddenSimilarLinesSelector]: {
                        display: 'none !important',
                    },
                },
            },

            [`&:not(.${diagramClasses.similarSvg})`]: {
                '& .similar-line': {
                    display: 'none',
                    stroke: grey[900],

                    [`&[data-starting-point-id="${activePointId}"]`]: {
                        display: visiblePointsSelector ? 'block' : 'none',
                    },
                },
            },

            [`&.${diagramClasses.similarSvg}`]: {
                '.similar-line': {
                    display: 'none',
                    pointerEvents: 'stroke',
                    cursor: 'pointer',
                    stroke: grey[400],
                    opacity: 0.6,

                    [`&${visibleSimilarLinesSelector}`]: visibleSimilarLinesSelector && {
                        display: visiblePointsSelector ? 'block' : 'none',
                    },

                    [`&.${diagramClasses.active},
                      &[data-starting-point-id="${activePointId}"],
                      &[data-ending-point-id="${activePointId}"],
                      &:hover`]: {
                        stroke: grey[900],
                        opacity: 1,
                    },

                    [`&.${diagramClasses.inactive}`]: {
                        display: 'none',
                    },
                },
            },

            '@keyframes stroke': {
                from: {
                    stroke: '#dee0e1',
                },
                to: {
                    stroke: '#687f96',
                },
            },
            '@keyframes text': {
                from: {
                    fontWeight: 400,
                },
                to: {
                    fontWeight: 700,
                },
            },
        },
    };
});
