import { LoadingModel } from '@platform/front-utils';
import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../clientRoute';
import { DiagramStore } from '../stores/DiagramStore';
import { NotificationStore } from '../stores/NotificationStore';
import { RootStore } from '../stores/RootStore';
import { Manifest } from '../types/manifest';
import { MessageType } from '../types/message';
import { Navigator } from '../types/navigator';
import { getSelectedFile } from '../utils/fileUtils';

export const fileFormModelObservables = {
    diagramStore: observable,
    history: observable,

    active: observable,
    files: observable,
    toRemove: observable,

    loadFiles: action.bound,
    openDefaultFile: action.bound,
    setDefaultFile: action.bound,
    downloadFile: action.bound,
    uploadFile: action.bound,
    removeFile: action.bound,
    onDatasetChange: action.bound,

    dropToRemove: action.bound,
    setActive: action.bound,
    setFiles: action.bound,
    setToRemove: action.bound,
};

export class FileFormModel extends LoadingModel {
    protected notificationStore: NotificationStore;
    protected diagramStore: DiagramStore;
    protected history: History;

    active: Manifest | null = null;
    files: Manifest[] = [];
    toRemove: Manifest | null = null;

    constructor(rootStore: RootStore) {
        super();
        this.notificationStore = rootStore.notificationStore;
        this.diagramStore = rootStore.diagramStore;
        this.history = rootStore.coreRootStore.history;
        makeObservable(this, fileFormModelObservables);
    }

    loadFiles(isWithRedirect: boolean): void {
        this.diagramStore
            .getFiles()
            .then((files) => {
                this.setFiles(files);
                isWithRedirect && this.openDefaultFile();
            })
            .catch(() => {
                this.notificationStore.add({ text: 'Не удалось загрузить список датасетов', type: MessageType.error });
            })
            .finally(() => {
                this.setIsLoaded(true);
            });
    }

    openDefaultFile(): void {
        if (this.files.length) {
            const byDefaultFIle = this.files.find((file) => file.isByDefault);
            const redirectFileId = byDefaultFIle?.id || this.files[0].id;
            this.history.push(generatePath(clientRoute.dataset, { id: redirectFileId }));
        }
    }

    setDefaultFile(id: string): void {
        this.diagramStore.setDefaultFile(id).then(this.setFiles);
    }

    downloadFile(item: Manifest): void {
        const { id, file: fileName } = item;
        this.diagramStore.getFile(id, fileName).then((file) => {
            const blob = new Blob([file], { type: 'application/octet-stream' });

            const navigator = window.navigator as Navigator;

            if (navigator && navigator.msSaveOrOpenBlob) {
                // IE variant
                navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();

                setTimeout(() => {
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                }, 200);
            }
        });
    }

    uploadFile(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        const file = event.target?.files?.[0];

        if (!file) {
            return Promise.reject();
        }

        const formData = new FormData();
        formData.append('file', file);

        return this.diagramStore.uploadFile(formData).then((uploadedFile) => {
            this.notificationStore.add({ text: 'Датасет загружен', type: MessageType.success });
            const newFiles = [...this.files, uploadedFile];
            this.setFiles(newFiles);
            this.history.push(generatePath(clientRoute.dataset, { id: uploadedFile.id }));
        });
    }

    removeFile(): Promise<void> {
        if (this.toRemove) {
            const removeFile = this.toRemove;

            return this.diagramStore
                .removeFile(removeFile.id)
                .then(() => {
                    const newFiles = this.files.filter((item) => item.id !== removeFile.id);
                    if (this.active?.id === removeFile.id) {
                        this.history.push(clientRoute.selectDataset);
                    }
                    this.setFiles(newFiles);

                    this.notificationStore.add({ text: 'Датасет удалён', type: MessageType.success });
                })
                .finally(() => {
                    this.dropToRemove();
                });
        }
        return Promise.reject();
    }

    onDatasetChange(id: string): void {
        this.setActive(getSelectedFile(id, this.files));
    }

    dropToRemove(): void {
        this.toRemove = null;
    }

    setActive(active: Manifest | null): void {
        this.active = active;
    }

    setFiles(files: Manifest[]): void {
        this.files = files;
    }

    setToRemove(toRemove: Manifest): void {
        this.toRemove = toRemove;
    }
}
