import { Button, DialogContent, Grid, Paper } from '@mui/material';
import { DialogTitle } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useRadarContext } from '../../hooks/contexts/useRadarContext';
import { useStore } from '../../hooks/contexts/useStore';
import { useFullscreenOnOpenAction } from '../../hooks/useFullscreenOnOpenAction';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import { ItemWithIndicator } from './ItemWithIndicator';
import { LegendRelationItem } from './LegendRelationItem';
import { LegendSection } from './LegendSection';
import { legendSx } from './legendSx';

const factorsContent = (
    <Grid container item spacing={3} wrap="nowrap">
        <ItemWithIndicator title="Высокий" fontSize={16} />
        <ItemWithIndicator title="Средний" fontSize={12} />
        <ItemWithIndicator title="Низкий" fontSize={8} />
    </Grid>
);

const relationTypeContent = (
    <Grid container item direction="column">
        <LegendRelationItem title="Устойчивая связь" />
        <LegendRelationItem title="Связь" strokeDasharray="10 4 1 4" />
        <LegendRelationItem title="Косвенная связь" strokeDasharray="4 2" />
    </Grid>
);

export const Legend = observer((): JSX.Element => {
    const { isDefaultView } = useStore();
    const { constants, dataset } = useRadarContext();
    const [isLegendOpen, openLegend, closeLegend] = useFlag(true);
    useFullscreenOnOpenAction(closeLegend);

    const typesElements = dataset?.types?.map((type, index) => (
        <ItemWithIndicator key={type} title={capitalizeFirstLetter(type)} color={constants?.colors[index]} />
    ));

    const objectTypeContent = (
        <Grid container item direction="column" spacing={1}>
            {typesElements}
        </Grid>
    );

    return (
        <React.Fragment>
            <Button variant="outlined" color="secondary" onClick={openLegend} sx={legendSx.button}>
                Легенда
            </Button>

            {isLegendOpen && (
                <Paper elevation={1} sx={legendSx.paper}>
                    <DialogTitle onCloseClick={closeLegend}>Легенда</DialogTitle>

                    <DialogContent>
                        <Grid container spacing={1}>
                            {isDefaultView && <LegendSection title="Потенциал" content={factorsContent} />}
                            <LegendSection title="Тип объекта" content={objectTypeContent} />
                            <LegendSection title="Тип связей" content={relationTypeContent} />
                        </Grid>
                    </DialogContent>
                </Paper>
            )}
        </React.Fragment>
    );
});
