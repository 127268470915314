import { makeSxStyles } from '@platform/front-ui';

export const diagramSx = makeSxStyles({
    wrapper: {
        position: 'relative',
        transition: 'max-width 0.5s ease-in-out',
        height: '100%',
        maxWidth: '100%',
    },
});
