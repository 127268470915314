import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { IconButton, Slider, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { scaleSliderSx } from './scaleSliderSx';

const MIN = 0;

export type ScaleSliderProps = {
    max: number;
    value: number;
    onChange: (value: number) => void;
    step?: number;
};

export const ScaleSlider = observer((props: ScaleSliderProps): JSX.Element => {
    const { max, onChange, step = 10, value } = props;

    const handleDecrease = (): void => {
        onChange(value <= MIN ? MIN : value - step);
    };

    const handleIncrease = (): void => {
        onChange(value >= max ? max : value + step);
    };

    const onSliderChange = (event: Event, value: number | number[]): void => {
        onChange(Array.isArray(value) ? 0 : value);
    };

    return (
        <Stack spacing={2} direction="column" sx={scaleSliderSx.container} alignItems="center">
            <IconButton onClick={handleIncrease}>
                <ZoomInIcon />
            </IconButton>
            <Slider
                orientation="vertical"
                color="secondary"
                size="small"
                value={value}
                step={step}
                max={max}
                min={MIN}
                sx={scaleSliderSx.track}
                onChange={onSliderChange}
            />
            <IconButton onClick={handleDecrease}>
                <ZoomOutIcon />
            </IconButton>
        </Stack>
    );
});
