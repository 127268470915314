import { grey, makeSxStyles } from '@platform/front-ui';

export const scaleSliderSx = makeSxStyles({
    container: {
        position: 'absolute',
        top: 40,
        left: 8,
        height: '160px',
        width: '16px',
        padding: '7px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
        borderRadius: '8px',
        backgroundColor: grey[0],
        zIndex: 99,
    },
    track: {
        cursor: 'grab',
    },
});
