import { observer } from 'mobx-react-lite';
import React from 'react';
import { IconProps } from '../../../types/icon';

export const LinkIcon = observer((props: IconProps): JSX.Element => {
    const { className } = props;
    return (
        <svg
            className={className}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.00002 2.00001L5.00001 4.00002L6.00002 5.00002L8.00002 3.00001C8.82502 2.17501 10.175 2.17501 11 3.00001C11.825 3.82501 11.825 5.17502 11 6.00002L9.00002 8.00003L10 9.00003L12 7.00002C13.38 5.62002 13.38 3.38001 12 2.00001C10.62 0.620007 8.38002 0.620007 7.00002 2.00001ZM8.00002 9.00003L6.00002 11C5.17501 11.825 3.82501 11.825 3.00001 11C2.17501 10.175 2.17501 8.82503 3.00001 8.00003L5.00001 6.00002L4.00001 5.00002L2.00001 7.00002C0.620003 8.38003 0.620003 10.62 2.00001 12C3.38001 13.38 5.62002 13.38 7.00002 12L9.00002 10L8.00002 9.00003ZM4.50001 8.50003L8.50002 4.50002L9.50002 5.50002L5.50002 9.50003L4.50001 8.50003Z"
                fill="currentColor"
            />
        </svg>
    );
});
