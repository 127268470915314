import { Grid, Typography } from '@mui/material';
import { grey } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';

export type LegendRelationProps = {
    title: string;
    strokeDasharray?: string;
};

export const LegendRelationItem = observer((props: LegendRelationProps): JSX.Element => {
    const { title, strokeDasharray } = props;

    return (
        <Grid container item alignItems="center" wrap="nowrap" spacing={2}>
            <Grid item>
                <svg height="18px" width="160px">
                    <line x1="0" y1="12" x2="160" y2="12" stroke={grey[900]} strokeDasharray={strokeDasharray} />
                </svg>
            </Grid>

            <Grid item>
                <Typography variant="body1">{title}</Typography>
            </Grid>
        </Grid>
    );
});
