import { Box, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import { makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useRadarContext } from '../../../../hooks/contexts/useRadarContext';
import { SimilarObjectsItemInfo } from './SimilarObjectsItemInfo';

const sx = makeSxStyles({
    contentWrapper: {
        position: 'relative',
        height: '100%',
    },
    contentInner: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        paddingRight: 2,
        overflow: 'hidden auto',
    },
});

export const SimilarObjectsInfo = observer((): JSX.Element => {
    const { activeSimilarObjects } = useRadarContext();

    if (!activeSimilarObjects) {
        return <React.Fragment />;
    }

    const { firstObject, secondObject } = activeSimilarObjects;

    return (
        <Box sx={sx.contentWrapper}>
            <Grid container direction="column" wrap="nowrap" sx={sx.contentInner}>
                <Grid item>
                    <SimilarObjectsItemInfo similarObjectsItem={firstObject} isFirstSource />
                </Grid>

                <Grid item mt={4} mb={4}>
                    <Divider />
                </Grid>

                <Grid item>
                    <SimilarObjectsItemInfo similarObjectsItem={secondObject} />
                </Grid>
            </Grid>
        </Box>
    );
});
