import { ToggleButtonGroup } from '@mui/lab';
import { grey, makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../hooks/contexts/useStore';
import { RadarViewButton } from './components/RadarViewButton';
import { SimilarFiltersButton } from './components/similar-filters-button/SimilarFiltersButton';

const sx = makeSxStyles({
    container: {
        position: 'absolute',
        bottom: 110,
        left: 8,
        backgroundColor: grey[0],
        zIndex: 10,
    },
});

export const SettingsGroupButton = observer((): JSX.Element => {
    const { isDefaultView } = useStore();

    return (
        <ToggleButtonGroup sx={sx.container} orientation="vertical">
            <RadarViewButton />
            {!isDefaultView && <SimilarFiltersButton />}
        </ToggleButtonGroup>
    );
});
