import { voidFunction } from '@platform/front-utils';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useRadarContext } from '../../hooks/contexts/useRadarContext';
import { FiltersModel } from '../../models/FiltersModel';
import { SelectFilter } from '../../types/filters';
import { FiltersForm } from './FiltersForm';

export const Filters = observer((): JSX.Element => {
    const { dataset, constants, handleFiltersChange, handlePointClick } = useRadarContext();

    const model = useMemo(() => new FiltersModel(), []);
    const { initialValues, fieldValues, updateColorsMap, setDataset } = model;
    const { search, factors, types } = fieldValues || initialValues;

    useEffect(() => {
        setDataset(dataset);
    }, [dataset]);

    useEffect(() => {
        handlePointClick(search?.code || '');
    }, [search]);

    useEffect(() => {
        handleFiltersChange(
            SelectFilter.factors,
            factors.map((item) => item.code.toString()),
        );
    }, [factors]);

    useEffect(() => {
        handleFiltersChange(
            SelectFilter.types,
            types.map((item) => item.code.toString()),
        );
    }, [types]);

    useEffect(() => {
        updateColorsMap(constants);
    }, [constants, dataset]);

    if (!dataset) {
        return <React.Fragment />;
    }

    return (
        <Formik initialValues={initialValues} onSubmit={voidFunction} enableReinitialize>
            <FiltersForm model={model} />
        </Formik>
    );
});
