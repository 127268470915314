import { TableCell, TableRow } from '@mui/material';
import { makeSxStyles, mergeSxStyles, Typography } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';

export type ObjectInfoTableRowProps = {
    title: string;
    value: JSX.Element | string;
    isLast?: boolean;
};

const sx = makeSxStyles({
    headerCell: {
        fontWeight: 500,
    },
    cell: {
        paddingY: 1,
        paddingRight: 1,
        verticalAlign: 'top',
        width: '50%',
        wordBreak: 'break-word',
    },
    last: {
        borderBottom: 'none',
    },
});

export const ObjectInfoTableRow = observer((props: ObjectInfoTableRowProps): JSX.Element => {
    const { title, value, isLast } = props;

    const headerCellSx = mergeSxStyles(sx.headerCell, sx.cell, isLast ? sx.last : {});

    return (
        <TableRow>
            <TableCell component="th" sx={headerCellSx}>
                {title}
            </TableCell>
            <TableCell sx={sx.cell}>
                <Typography>{value}</Typography>
            </TableCell>
        </TableRow>
    );
});
