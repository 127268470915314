import { Alert } from '@mui/lab';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { toastDelayMs } from '../../constants/toast';
import { useStore } from '../../hooks/contexts/useStore';
import { notificationSx } from './notificationSx';

export const Notification = observer((): JSX.Element => {
    const { messages, shift } = useStore().notificationStore;

    useEffect(() => {
        if (messages.length > 0) {
            setTimeout(() => {
                shift();
            }, toastDelayMs);
        }
    }, [messages]);

    if (messages.length === 0) {
        return <React.Fragment />;
    }

    const { type, text } = messages[0];

    return ReactDOM.createPortal(
        <Alert variant="standard" sx={notificationSx.alert} severity={type}>
            {text}
        </Alert>,
        document.body,
    );
});
