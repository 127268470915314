import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { CodeTitle, SxStyle } from '@platform/front-types';
import { BaseAutocompleteField } from '@platform/front-ui';
import { TextFieldProps } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React, { SyntheticEvent } from 'react';

export type SearchFieldProps = {
    options: CodeTitle[];
    handleSearch: (value: string) => void;
};

const sx: SxStyle = {
    minWidth: '320px',

    '[class*="MuiAutocomplete-popupIndicator"]': {
        display: 'none',
    },
};

const textFieldParams: Partial<TextFieldProps> = {
    InputProps: {
        startAdornment: (
            <InputAdornment position="start">
                <SearchIcon />
            </InputAdornment>
        ),
    },
};

export const SearchField = observer((props: SearchFieldProps): JSX.Element => {
    const { options, handleSearch } = props;

    const onInputChange = (event: SyntheticEvent, value: string): void => {
        handleSearch(value);
    };

    return (
        <BaseAutocompleteField
            fieldName="search"
            options={options}
            textFieldParams={textFieldParams}
            onInputChange={onInputChange}
            placeholder="Введите значение"
            size="small"
            sx={sx}
        />
    );
});
