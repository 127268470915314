import { ReactNode, RefObject } from 'react';

export type TooltipParams = {
    text: ReactNode;
    left: number;
    top: number;
    type: TooltipType;
};

export type TooltipEvent = {
    id: string;
    left: number;
    top: number;
    labelText?: string;
    isSub?: boolean;
};

export type MouseHandle = {
    isDragging: boolean;
    x: number;
    y: number;
};

export type ViewBox = {
    x: number;
    y: number;
    scale: number;
};

export type Constants = {
    colors: string[];
    radius: number;
    padding: number;
};

export type Filter = {
    factors: string[];
    types: string[];
};

export type SubLevel = {
    id: number;
    title: string;
};

export type SvgRefs = {
    panHandler: RefObject<MouseHandle>;
    mouseHandler: RefObject<MouseHandle>;
};

export enum TooltipType {
    bottom = 'bottom',
    top = 'top',
}
