class ClientRoute {
    root = '/';

    selectDataset = '/dataset';
    dataset = '/dataset/:id';
    slice = '/dataset/:id/slice/:sliceId';
    subLevel = '/dataset/:id/sub-level/:subLevelId';
    subLevelSlice = '/dataset/:id/sub-level/:subLevelId/slice/:sliceId';
}

export const clientRoute = new ClientRoute();
