import { CodeTitle, CodeTitleNull } from '@platform/front-types';

export type ColorsMap = Record<string, string>;

export type DiagramFilters = {
    search: CodeTitleNull;
    factors: CodeTitle[];
    types: CodeTitle[];
};

export type RenderOptionLabel = (title: JSX.Element, value: string, isDropdown: boolean) => JSX.Element;

export type SimilarFilters = Record<SimilarRelationType, boolean>;

export enum SelectFilter {
    factors = 'factors',
    types = 'types',
}

export enum SimilarRelationType {
    strong = 'strong',
    common = 'common',
    weak = 'weak',
}
