import { SearchParam, SearchParamProperty } from '../types/searchParam';

export const getSearchParams = (): string => {
    return window.location.search;
};
export const getURLSearchParams = (): URLSearchParams => {
    return new URLSearchParams(getSearchParams());
};
export const getSearchParam = (property: SearchParam): string | null => {
    const searchParams = getURLSearchParams();
    return searchParams.get(property);
};

export const setSearchParam = (property: SearchParam, value: string): string => {
    const searchParams = getURLSearchParams();
    searchParams.set(property, value);
    return searchParams.toString();
};

export const setSearchParams = (params: SearchParamProperty[]): string => {
    const searchParams = getURLSearchParams();
    params.forEach((param) => {
        const { property, value } = param;
        searchParams.set(property, value);
    });
    return searchParams.toString();
};

export const removeSearchParam = (property: SearchParam): string => {
    const searchParams = getURLSearchParams();
    searchParams.delete(property);
    return searchParams.toString();
};

export const removeSearchParams = (propertys: SearchParam[]): string => {
    const searchParams = getURLSearchParams();
    propertys.forEach((property) => {
        searchParams.delete(property);
    });
    return searchParams.toString();
};
