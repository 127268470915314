import { RootProps } from '@platform/front-core';
import { getPalette, isPaletteEnabled, TotPalette } from '@platform/front-ui';
import { createBrowserHistory } from 'history';
import { Env } from './types/rootStore';

const projectName = process.env.REACT_APP_PROJECT_NAME;
const apiUrl = process.env.REACT_APP_API_URL;

const env: Env = {
    apiUrl,
    apiRadarUrl: process.env.REACT_APP_RADAR_API_URI,
    authUrl: process.env.REACT_APP_AUTH_URL,
    serviceUrl:
        process.env.REACT_APP_SERVICE_URL || typeof apiUrl === 'string' ? apiUrl?.replace('api', 'service') : undefined,
    projectName,
    totCopyright: process.env.REACT_APP_TOT_COPYRIGHT,
    totCopyrightRu: process.env.REACT_APP_TOT_COPYRIGHT_RU,
    totCopyrightEn: process.env.REACT_APP_TOT_COPYRIGHT_EN,
    totCopyrightStartYear: process.env.REACT_APP_TOT_COPYRIGHT_START_YEAR,
    totTel: process.env.REACT_APP_TOT_TEL,
    totMail: process.env.REACT_APP_TOT_MAIL,
    startPageText: process.env.REACT_APP_START_PAGE_TEXT,
    termsOfServiceLinkRu: process.env.REACT_APP_TERMS_OF_SERVICE_RU,
    termsOfServiceLinkEn: process.env.REACT_APP_TERMS_OF_SERVICE_EN,
    pfDelay: Number(process.env.REACT_APP_PF_DELAY) || 30000,
    formServiceUrl: process.env.REACT_APP_FORM_UI_SERVICE_URL,
    yaMetrikaId: Number(process.env.REACT_APP_METRIKA_ID),
};

let features: RootProps['features'];
let ru = {};
let en = {};

if (projectName) {
    features = require(`./customization/${projectName}/features`).default;
    if (projectName !== 'default') {
        try {
            ru = require(`./customization/${projectName}/locales/ru`).default;
            en = require(`./customization/${projectName}/locales/ru`).default;
            // eslint-disable-next-line no-empty
        } catch (error) {}
    }
}

const locales: RootProps['locales'] = { ru, en };
const history = createBrowserHistory();

let palette: TotPalette | undefined;
if (isPaletteEnabled(process.env.REACT_APP_ENABLE_PALETTE)) {
    palette = getPalette();
}

export { env, features, locales, history, palette };
