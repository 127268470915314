import { Box, Grid } from '@mui/material';
import { Loader } from '@platform/front-ui';
import { useResizeObserver } from '@platform/front-utils';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef } from 'react';
import { diagramClasses } from '../../../../constants/diagram';
import { useDiagramContext } from '../../../../hooks/contexts/useDiagramContext';
import { useRadarContext } from '../../../../hooks/contexts/useRadarContext';
import { useStore } from '../../../../hooks/contexts/useStore';
import { useSvgModelSetters } from '../../../../hooks/useSvgModelSetters';
import { SvgModel } from '../../../../models/svg/SvgModel';
import { MouseHandle } from '../../../../types/diagram';
import { getSvgSx } from './svgSx';

export type SvgProps = {
    id: string;
    fileName: string;
    diameter: number;
    padding: number;
};

export const Svg = observer((props: SvgProps): JSX.Element => {
    const { id, fileName, diameter } = props;
    const rootStore = useStore();
    const { view, isDefaultView } = rootStore;
    const diagramModel = useDiagramContext();
    const { similarFilters } = diagramModel;
    const { activeDataset, filters } = useRadarContext();

    const panHandler = useRef<MouseHandle>({ isDragging: false, x: 0, y: 0 });
    const mouseHandler = useRef<MouseHandle>({ isDragging: false, x: 0, y: 0 });

    const model = useMemo(
        () =>
            new SvgModel(rootStore, diagramModel, {
                panHandler,
                mouseHandler,
            }),
        [rootStore, diagramModel, panHandler.current, mouseHandler.current],
    );
    const {
        svg,
        viewBox,
        isLoading,
        load,
        updateViewBox,
        onViewBoxChange,
        panStart,
        panMove,
        panStop,
        mouseOver,
        mouseOut,
        sectorScale,
        setWidth,
    } = model;
    const [wrapperRef] = useResizeObserver<HTMLDivElement>(setWidth);
    useSvgModelSetters(props, model, wrapperRef);
    const { activeSlice, scaleLevel, scaleCount, handleScale } = diagramModel;

    useEffect(() => {
        load();
    }, [id, fileName, view]);

    useEffect(() => {
        if (!scaleCount) {
            return;
        }
        updateViewBox();
    }, [activeSlice, diameter, scaleCount, scaleLevel]);

    useEffect(() => {
        onViewBoxChange();
    }, [viewBox]);

    // увеличение сектора
    useEffect(() => {
        sectorScale();
    }, [activeSlice]);

    useEffect(() => {
        // на случай unmount`a компонента раньше, чем отпустили мышь
        return () => {
            document.removeEventListener('mouseup', panStop);
        };
    }, []);

    const mouseWheel = (event: React.WheelEvent): void => {
        handleScale(Math.sign(event.deltaY));
    };

    const svgDomObject: Document = useMemo(() => new DOMParser().parseFromString(svg, 'text/xml'), [svg]);
    // svgDomObject нужен т.к. sx срабатывает раньше, чем отрисовывается диаграмма
    const sx = useMemo(
        () =>
            getSvgSx({
                svgDomObject,
                isDefaultView,
                activePointId: activeDataset?.id || '',
                filters,
                similarFilters,
            }),
        [svgDomObject, isDefaultView, activeDataset, filters, similarFilters],
    );

    return (
        <Grid
            container
            direction="column"
            sx={sx.wrapper}
            flex={1}
            className={diagramClasses.svgWrapper}
            ref={wrapperRef}
        >
            {isLoading ? (
                <Loader fullSize />
            ) : svg ? (
                <Box
                    onMouseDown={panStart}
                    onMouseMove={panMove}
                    onMouseOver={mouseOver}
                    onMouseOut={mouseOut}
                    onWheel={mouseWheel}
                    className={classNames(diagramClasses.svg, { [diagramClasses.similarSvg]: !isDefaultView })}
                    sx={sx.svg}
                    dangerouslySetInnerHTML={{ __html: svg }}
                />
            ) : (
                <React.Fragment />
            )}
        </Grid>
    );
});
