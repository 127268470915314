import { Paper, Popper } from '@mui/material';
import { ModalProps } from '@platform/front-types';
import { DialogTitle, makeSxStyles } from '@platform/front-ui';
import { voidFunction } from '@platform/front-utils';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { RefObject, useEffect, useState } from 'react';
import { zIndexes } from '../../../../constants/zIndexes';
import { useDiagramContext } from '../../../../hooks/contexts/useDiagramContext';
import { useStore } from '../../../../hooks/contexts/useStore';
import { SimilarFiltersForm } from './similar-filters-form/SimilarFiltersForm';

export type SimilarFiltersPopperProps = ModalProps & {
    anchorEl: RefObject<HTMLButtonElement>;
};

const sx = makeSxStyles({
    container: {
        width: '320px',
        zIndex: zIndexes.similarFilters,
    },
});

export const SimilarFiltersPopper = observer((props: SimilarFiltersPopperProps): JSX.Element => {
    const { anchorEl, open, onCancel } = props;
    const { isFullscreen } = useStore();
    const { similarFilters, dropSimilarFilters } = useDiagramContext();
    const [key, setKey] = useState('');

    useEffect(() => {
        // Исправляет placement для popper
        setTimeout(() => {
            setKey('similar-filters' + isFullscreen);
        });
    }, [isFullscreen]);

    useEffect(() => {
        return dropSimilarFilters;
    }, []);

    return (
        <Popper key={key} placement="right-end" open={open} anchorEl={anchorEl?.current} sx={sx.container}>
            <Paper elevation={1}>
                <DialogTitle onCloseClick={onCancel}>Настройка связей</DialogTitle>

                <Formik initialValues={similarFilters} onSubmit={voidFunction} enableReinitialize>
                    <SimilarFiltersForm />
                </Formik>
            </Paper>
        </Popper>
    );
});
