import { makeSxStyles } from '@platform/front-ui';
import { toastAnimDurationMs, toastHideDelayMs } from '../../constants/toast';
import { zIndexes } from '../../constants/zIndexes';

export const notificationSx = makeSxStyles({
    alert: {
        position: 'fixed',
        bottom: '60px',
        left: '40px',
        zIndex: zIndexes.alert,

        animationDelay: `0s, ${toastHideDelayMs}ms`,
        animationDuration: `${toastAnimDurationMs}ms, ${toastAnimDurationMs}ms`,
        animationName: 'show, hide',

        '@keyframes show': {
            from: {
                opacity: 0,
                transform: 'scale(1)',
            },
            '75%': {
                transform: 'scale(1.1)',
            },
            to: {
                opacity: 1,
                transform: 'scale(1)',
            },
        },
        '@keyframes hide': {
            from: {
                opacity: 1,
            },
            to: {
                opacity: 0,
            },
        },
    },
});
