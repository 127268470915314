import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Checkbox, Chip } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete';
import { CodeTitle } from '@platform/front-types';
import { AutocompleteField, AutocompleteFieldProps, EllipsisBox, makeSxStyles } from '@platform/front-ui';
import { identity } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { RenderOptionLabel } from '../../../types/filters';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type FilterAutocompleteProps = {
    label: string;
    fieldName: string;
    options: CodeTitle[];
    renderOptionLabel?: RenderOptionLabel;
};

const sx = makeSxStyles({
    select: {
        minWidth: '280px',

        'span[class*="MuiAutocomplete-tag"]': {
            fontSize: '12px',
            marginTop: '-7px',
        },

        '[class*="MuiAutocomplete-tag"]': {
            height: '16px !important',

            '[class*="MuiTypography"], [class*="MuiBox-root"]': {
                fontSize: '12px',
            },
        },

        '[class*="MuiAutocomplete-input"]': {
            minWidth: '0 !important',
        },
    },
    text: {
        fontSize: '12px',
        lineHeight: '12px',
    },
});

const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
};

const limitTags = 2;

export const FilterAutocomplete = observer((props: FilterAutocompleteProps): JSX.Element => {
    const { fieldName, label, options, renderOptionLabel = identity } = props;
    const totalOptions = options.length;
    const labelWithWrapper = <Box mr={1.5}>{label}</Box>;

    const getOptionLabel = (option: CodeTitle, correctLength: number, isDropdown = false): string => {
        const text = capitalizeFirstLetter(option.title);
        return renderOptionLabel(
            <EllipsisBox text={text} correctLength={correctLength} />,
            option.code,
            isDropdown,
        ) as unknown as string;
    };
    const renderOption: AutocompleteFieldProps<CodeTitle, true>['renderOption'] = (props, option, { selected }) => {
        const title = getOptionLabel(option, 40);
        return (
            <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                {title}
            </li>
        );
    };

    const getLabel = (option: CodeTitle, isDropdown = false): string => {
        return getOptionLabel(option, 10, isDropdown);
    };

    const renderTags = (options: CodeTitle[], getTagProps: AutocompleteRenderGetTagProps): ReactNode => {
        const selectedOptionsCont = options.length;
        const extraItems = selectedOptionsCont - limitTags;
        if (totalOptions === selectedOptionsCont) {
            return <Box sx={sx.text}>Все</Box>;
        }
        return options.map((option, index) => {
            const isLastItem = index === selectedOptionsCont - 1;
            return index < limitTags ? (
                <Chip {...getTagProps({ index })} label={getLabel(option, true)} />
            ) : isLastItem ? (
                <Box sx={sx.text}>+{extraItems}</Box>
            ) : (
                <React.Fragment />
            );
        });
    };

    return (
        <AutocompleteField
            multiple
            disableCloseOnSelect
            limitTags={limitTags}
            fieldName={fieldName}
            label={labelWithWrapper}
            options={options}
            getLabel={getLabel}
            renderOption={renderOption}
            renderTags={renderTags}
            onKeyDown={onKeyDown}
            disableClearable
            placeholder="Ничего не выбрано"
            size="small"
            sx={sx.select}
        />
    );
});
