export const semiArcPath = (radius: number, padding: number) =>
    `M${padding},${radius} A${radius - padding},${radius - padding} 0 0,1 ${radius - 0.00000000000006},${padding}`;

export const setOffsetWithLimit = (offset: number, scale: number, diameter: number, start: number): number => {
    if (offset > diameter - scale) {
        return diameter - scale;
    }

    if (offset < start) {
        return start;
    }

    return offset;
};
