import { useEffect, useMemo } from 'react';
import { ImperativePanelHandle } from 'react-resizable-panels';
import { LocalStorage } from '../types/localstorage';

const defaultSidebarSize = 20;

export const UseMainContentSizes = (panel: ImperativePanelHandle | null): number => {
    const panelSize = panel?.getSize();

    useEffect(() => {
        if (panel && panelSize) {
            localStorage.setItem(LocalStorage.sidebarSize, panelSize.toString());
        }
    }, [panelSize]);

    return useMemo(() => parseInt(localStorage.getItem(LocalStorage.sidebarSize) || '') || defaultSidebarSize, []);
};
