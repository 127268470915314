import { Box, Table, TableBody } from '@mui/material';
import { makeSxStylesWithProps } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useRadarContext } from '../../../../hooks/contexts/useRadarContext';
import { ColorsMap } from '../../../../types/filters';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import { ObjectInfoTableRow } from './ObjectInfoTableRow';

export type ObjectInfoContentProps = {
    colorsMap: ColorsMap;
};

type sxProps = {
    dotColor: string;
};

const getSx = makeSxStylesWithProps((props: sxProps) => {
    const { dotColor } = props;
    return {
        typeWrapper: {
            textIndent: '14px',
            position: 'relative',

            '&::before': {
                content: '""',
                position: 'absolute',
                left: 0,
                top: 7,
                height: 8,
                width: 8,
                borderRadius: 8,
                marginRight: 1,
                backgroundColor: dotColor,
            },
        },
    };
});

export const ObjectInfoTable = observer((props: ObjectInfoContentProps): JSX.Element => {
    const { colorsMap } = props;
    const { activeDataset } = useRadarContext();

    if (!activeDataset) {
        return <React.Fragment />;
    }

    const sx = getSx({
        dotColor: colorsMap[activeDataset.type.trim().toLowerCase()],
    });

    const otherRowsCount = activeDataset.other.length;

    const otherRows: JSX.Element[] = activeDataset.other.map((otherItem, index) => (
        <ObjectInfoTableRow {...otherItem} key={otherItem.value} isLast={otherRowsCount === index + 1} />
    ));

    const objectTypeValue = <Box sx={sx.typeWrapper}>{capitalizeFirstLetter(activeDataset.type)}</Box>;

    return (
        <Table>
            <TableBody>
                <ObjectInfoTableRow title="Тип объекта" value={objectTypeValue} />
                <ObjectInfoTableRow title="Направление" value={activeDataset.slice} />
                {!!activeDataset.subSlice && (
                    <ObjectInfoTableRow title="Поднаправление" value={activeDataset.subSlice} />
                )}
                <ObjectInfoTableRow title="Зрелость" value={activeDataset.scaleLabel} />
                <ObjectInfoTableRow title="Масштабность" value={activeDataset.factor} isLast={otherRowsCount === 0} />
                {otherRows}
            </TableBody>
        </Table>
    );
});
