import { Box } from '@mui/material';
import { Typography } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { subsTooltipSx } from './subsTooltipSx';

export type SubsTooltipProps = {
    title?: string;
    items?: string[];
    onClick?: (id: number) => void;
};

export const SubsTooltip = observer((props: SubsTooltipProps): JSX.Element => {
    const { title, items, onClick } = props;

    if (!title) {
        return <React.Fragment />;
    }

    const itemsElements = items?.map((item, index): JSX.Element => {
        const handleClick = () => {
            onClick?.(index);
        };
        return (
            <Box key={`${item}-${index}`} sx={subsTooltipSx.item} onClick={handleClick}>
                {item}
            </Box>
        );
    });

    return (
        <Box sx={subsTooltipSx.container}>
            <Typography sx={subsTooltipSx.info} component="p">
                Направление:
            </Typography>
            <Typography sx={subsTooltipSx.title} component="h2">
                {title}
            </Typography>
            <Typography sx={subsTooltipSx.info} component="p">
                Поднаправления:
            </Typography>
            {itemsElements}
        </Box>
    );
});
